import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function PostEN() {
  const [postData, setPost] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "postEN"]{
                title,
                slug,
                autor,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                }
            }`
      )
      .then((data) => setPost(data))
      .catch(console.error);
  }, []);

  return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container max-w-screen-xl mx-auto">
        <h1 className="text-3xl flex justify-center pb-8">
          EXTREMES blog
        </h1>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {postData &&
            postData.map((postEN, index) => (
              <article>
                <Link to={"/en/blog/" + postEN.slug.current} key={postEN.slug.current}>
                  <span
                    className="block h-64 relative rounded shadow leading-snug bg-white"
                    key={index}
                  >
                    <img
                      src={postEN.mainImage.asset.url}
                      alt={postEN.mainImage.alt}
                      className="w-full h-full rounded-r object-cover absolute"
                    />
                    <span className="block relative h-full flex justify-end items-end pr-4 pb-4">
                      <h3 className="text-gray-800 text-lg font-blog px-3 py-4 bg-blue-700 text-blue-100 bg-opacity-75 rounded">
                        {postEN.title}
                      </h3>
                    </span>
                  </span>
                </Link>
              </article>
            ))}
        </div>
      </section>
    </main>
    <FormEN />
    <FooterEN />
    </>
  );
}