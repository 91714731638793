import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from "./components/Home";
import About from "./components/About";
import SinglePost from "./components/SinglePost";
import Post from "./components/Post";
import Project from "./components/Project";
import NavBar from "./components/NavBar";
import Event from "./components/Event";
import Report from "./components/Report";
import Team from "./components/Team";
import WorkPackage from "./components/WorkPackage";
import Vest from "./components/Vest";
import SingleVest from "./components/SingleVest";
import Rad from "./components/Rad";
import SingleRad from "./components/SingleRad";
import Oprojektu from "./components/Oprojektu";
import CiljeviProjekta from "./components/CiljeviProjekta";
import Kontakt from "./components/Kontakt";
import WorkPackages from "./components/WorkPackages";
import Footer from "./components/Footer";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarMobile from "./components/NavBarMobile";
import Katalog from "./components/Katalog";
import HomeEN from "./components/HomeEN";
import Publikacije from "./components/Publikacije";
import { useState } from "react";
import Form from "./components/Form";
import SinglePostEN from "./components/SinglePostEN";
import PostEN from "./components/PostEN";
import ReportEN from "./components/ReportEN";
import EventEN from "./components/EventEN";
import TeamEN from "./components/TeamEN";
import WorkPackageEN from "./components/WorkPackageEN";
import SingleVestEN from "./components/SingleVestEN";
import VestEN from "./components/VestEN";
import SingleRadEN from "./components/SingleRadEN";
import RadEN from "./components/RadEN";
import KatalogEN from "./components/KatalogEN";
import PublikacijeEN from "./components/PublikacijeEN";
import OprojektuEN from "./components/OprojektuEN";
import CiljeviProjektaEN from "./components/CiljeviProjektaEN";
import KontaktEN from "./components/KontaktEN";
import WorkPackagesEN from "./components/WorkPackagesEN";
import Suse from "./components/Suse";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
const TRACKING_ID = "G-5QR6GQQNBK";
ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route component={Home} path="/" exact />
          <Route component={HomeEN} path="/en" exact />
          <Route component={About} path="/about" />
          <Route component={SinglePost} path="/blog/:slug" />
          <Route component={Post} path="/blog" />
          <Route component={Project} path="/project" />
          <Route component={Event} path="/pozivi-za-dogadjaje" />
          <Route component={Report} path="/izvestaji" />
          <Route component={Team} path="/tim" />
          <Route component={WorkPackage} path="/package" />
          <Route component={SingleVest} path="/vesti/:slug" />
          <Route component={Vest} path="/vesti" />
          <Route component={SingleRad} path="/radovi/:slug" />
          <Route component={Rad} path="/naucni-radovi" />
          <Route component={Katalog} path="/katalog" />
          <Route component={Publikacije} path="/Publikacije" />
          <Route path="/o-projektu" component={Oprojektu} />
          <Route path="/ciljevi-projekta" component={CiljeviProjekta} />
          <Route path="/kontakt" component={Kontakt} />
          <Route path="/work-packages" component={WorkPackages} />
          <Route path="/suse" component={Suse} />

          <Route component={SinglePostEN} path="/en/blog/:slug" />
          <Route component={PostEN} path="/en/blog" />
          <Route component={EventEN} path="/en/upcoming-events" />
          <Route component={ReportEN} path="/en/reports" />
          <Route component={TeamEN} path="/en/team" />
          <Route component={WorkPackageEN} path="/en/package" />
          <Route component={SingleVestEN} path="/en/news/:slug" />
          <Route component={VestEN} path="/en/news" />
          <Route component={SingleRadEN} path="/en/scientific-papers/:slug" />
          <Route component={RadEN} path="/en/scientific-papers" />
          <Route component={KatalogEN} path="/en/catalog" />
          <Route component={PublikacijeEN} path="/en/publications" />
          <Route path="/en/about-project" component={OprojektuEN} />
          <Route path="/en/goals-of-project" component={CiljeviProjektaEN} />
          <Route path="/en/contact" component={KontaktEN} />
          <Route path="/en/work-packages" component={WorkPackagesEN} />
        </Switch>
      </BrowserRouter>
    </>
  );
}

export default App;
