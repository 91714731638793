import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";


export default function SingleRad() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  const [singleRad, setsingleRad] = useState(null);
  const { slug } = useParams();


  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            body,
            link,
            publishedAt,
            citiranost,
            autori
        }`
      )
      .then((data) => setsingleRad(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleRad) return <div>Loading...</div>;

  return (
    <>
    {allDevices && (
    <NavBar />
)}
  {isSmallDevice && (
      <NavBarMobile/>
)}
    <main className="bg-white-100 min-h-screen px-4 md:p-12">
      <article className="container shadow-lg mx-auto max-w-screen-xl">
      <header className="relative">
          <div className="md:absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-white">
              <h1 className="text-2xl lg:text-2xl mb-4">
                {singleRad.title}
              </h1>
              <p className="text-gray-800 py-2 text-center">
              {singleRad.autori}
                      </p>
              <p className="text-gray-800 py-2 mb-2 text-center">
              {new Date(singleRad.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
            </div>
          </div>
        </header>
        <div className="flex flex-col md:flex-row justify-between my-4 px-4 md:px-16  lg:px-54 py-12 lg:py-28">
          <BlockContent
            blocks={singleRad.body}
            projectId="krjmu28b"
            dataset="production" className="my-4 mr-4"
          />
        <div className="bg-blue-600 h-full my-8 p-4 rounded">
            <h2 className="text-white text-xl mb-2">Citiranost</h2>
            <p className="text-white">{singleRad.citiranost}</p>
            <br/>
            <button className="bg-white hover:text-blue-700 text-black font-bold py-2 px-4 border border-blue-700 rounded">
                    <a href={singleRad.link} target="_blank">
                            Pročitaj više</a></button>
          </div>
        </div>
      </article>
 
      </main>
      <Form />
    <Footer />
      </>
  );
}