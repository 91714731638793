import React from "react";
import image1 from "../extremes-horizontalno.png";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function CiljeviProjektaEN () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
      return (
    <>
        {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center items-center">
                <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 pb-2">
                Project Goals
                </h1>
            </div>
        <div className="max-w-xl justify-center items-center">
            <img
              src={image1}
              alt="Project Image"
              className="object-contain max-w-full h-auto max-w-md"
            />
        </div>
    </div>
    </div>
   


    <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center">
                <h2 className="text-3xl font-semibold mb-2 text-gray-800">
                What are the goals of the EXTREMES project?
                </h2>
            </div>
         <div className="max-w-xl">
             <p className="text-lg text-gray-700 text-justify mb-4">
             The "EXTREMES" project aims to conduct a detailed analysis of extreme weather and climate events (heatwaves, droughts, heavy rainfall, hail damage, dense fog, etc.) in the past and their projections for the future. One focus will also be on establishing an easy, open, and reliable access to this type of data and information to support a wide range of potential users, in order to assess the risks and impacts of these extremes on various aspects of life in our country.
            </p>
        </div>
  </div>
</div>

      <hr className="my-8" />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center">
                <h2 className="text-3xl text-blue-400 font-semibold mb-2 text-gray-800">
                Who benefits from the EXTREMES project?
            </h2>
            </div>
         <div className="max-w-xl">
             <p className="text-lg text-gray-700 text-justify mb-4">
             Climate change and the increasing number of extremes associated with it represent one of the global problems that gather initiatives and research from around the world, and our research group has been building strong ties with international research groups in this field for years. The results of a regional climate model developed within our group have become part of the Digital Atlas of the Intergovernmental Panel on Climate Change of the United Nations (
              <a
                href="https://interactive-atlas.ipcc.ch"
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://interactive-atlas.ipcc.ch
              </a>
              ), which represents the globally most relevant source of information on climate change. Following this good tradition, we are confident that the results of the EXTREMES project will have an impact internationally.
            </p>
            <p className="text-lg text-gray-700 text-justify mb-4">
            Considering the comprehensive impact of weather and climate events on people, the environment, and infrastructure, the results that will emerge from the EXTREMES project will be useful for the general public in various fields and activities. In addition to classifying extreme events and supporting their better understanding, which will serve a more comprehensive understanding of their impact in the past in our country, our team will also provide insight into future trends in extreme events that can be used in planning strategically important activities such as agriculture, energy, environmental protection, visual resources, infrastructure, insurance, tourism, etc.
            </p>
          </div>
        </div>
      </div>

      <FormEN />
    <FooterEN />
    </>
  );
}