import React, { useState, useEffect } from "react";
import sanityClient from "../client.js";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function TeamEN() {
  const [teamData, setTeam] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "teamEN"]{
                title,
                description,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                }
            }`
      )
      .then((data) => setTeam(data))
      .catch(console.error);
  }, []);

  return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container mx-auto">
        <h1 className="text-3xl flex justify-center">
          Team members
        </h1>
        <h2 className="text-lg text-gray-600 flex justify-center mb-12">
        Project participants and their short biographies        </h2>
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-16 text-center">
          {teamData && teamData.map((teamEN, index) => (
              <article>
                  {/* <span
                    className="block rounded leading-snug bg-white"
                    key={index}
                  >
                    <img
                      src={team.mainImage.asset.url}
                      alt={team.mainImage.alt}
                      className="w-full h-full rounded object-cover"
                    />
                    <span className="block h-full">
                      <h3 className="text-center text-gray-800 text-lg font-blog px-3 py-4 text-blue-500">
                        {team.title}
                      </h3>
                      <p className="text-justify text-gray-800">{team.description}</p>
                    </span>
                  </span> */}

                  <Card>
      <Card.Img variant="top" src={teamEN.mainImage.asset.url}
                      alt={teamEN.mainImage.alt}
                      className="w-full h-full rounded object-cover" />
      <Card.Body>
        <Card.Title className="text-center text-gray-800 text-lg font-blog px-3 py-4 text-blue-500">{teamEN.title}</Card.Title>
        <Card.Text className="text-justify text-gray-800">
        {teamEN.description}
        </Card.Text>
      </Card.Body>
    </Card>
              </article>
            ))}
        </div>
      </section>

    </main>
    <FormEN />
    <FooterEN />
    </>
  );
}