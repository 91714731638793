import React, {useEffect, useState} from "react";
import sanityClient from "../client";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function ReportEN() {
    const [reportData, setreportData] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "reportEN"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setreportData(data))
        .catch(console.error);
    }, []);
    return (
        <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
        <main className="bg-white-100 min-h-screen p-12">
            <section className="container max-w-screen-xl mx-auto">
                <h1 className="text-4xl flex pb-8">Technical reports</h1>
                {/* <h2 className="text-2xl flex pb-8">COMING EXTREMELY SOON </h2> */}
                <section className="grid grid-cols-1 gap-4">
                    {reportData && reportData.map((reportEN, index) => (

                    
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={reportEN}>
                        <h3 className="text-blue-600 text-2xl hover:text-blue-700">
                                {reportEN.naslov}
                        </h3>
                        <p className="text-gray-700 py-4 dark:text-gray-400 mb-2">{reportEN.opis}</p>
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        <a
                            href={reportEN.link}
                            alt={reportEN.dugme}
                            target="_black"
                            rel="noopener noreferrer" download
                            >
                                {reportEN.dugme}</a></button>
                    </article>
                    ))}
                </section>
            </section>
        </main>
        <FormEN />
    <FooterEN />
        </>
    )
}