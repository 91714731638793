import React from "react";
import image1 from "../o-projektu-grupna-fotografija.png";
import image2 from "../extremes-horizontalno-blue.png";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";


import imagePoster1 from "../e-poster1.png";
import imagePoster2 from "../e-poster2.png";
import imagePoster3 from "../e-poster3.png";

export default function Oprojektu () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
            {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
<div className="py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <img
              src={imagePoster2}
              alt="Project Image 1"
              className="object-contain max-w-full h-auto"
            />
            <img
              src={imagePoster1}
              alt="Project Image 2"
              className="object-contain max-w-full h-auto"
            />
            <img
              src={imagePoster3}
              alt="Project Image 3"
              className="object-contain max-w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 poster-color">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex justify-center items-center">
            <img
              src={image1}
              alt="Project Image"
              className="object-contain max-w-full h-auto max-w-md"
            />
          </div>
          <div className="max-w-xl"> 
            <h1 className="text-5xl font-semibold mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">O projektu</h1>
            <p className="text-lg text-gray-700 text-justify mb-4">
              "EXTREMES" se bavi istraživanjem sve češćih i intenzivnijih
              vremenskih i klimatskih ekstrema, kao što su toplotni talasi,
              suše, poplave i oluje, koji imaju uticaj na ljude i prirodu i
              izazivaju štete i gubitke u našoj zemlji. Od 2000. ove štete u
              našoj zemlji su procenjene na preko 7 milijardi evra. Tokom
              projekta naš tim će koristi savremene i inovativne metodološke
              pristupe i najnovije tehnologije kako bi objasnio ove događaje,
              njihov uzrok, analizirao trenutne posledice i moguće rizike u
              budućnosti.
            </p>
            <p className="text-lg text-gray-700 text-justify mb-4  ">
            Glavni problemi kojima će se projekat baviti uključuju identifikaciju 
            relevantnih ekstremnih događaja, analizu sinoptičkih situacija povezanih 
            s tim događajima, unapređenje modela za predviđanje padavina, grada, jakih 
            vetrova i munja, prognozu ekstremnih događaja magli, kao i određivanje 
            uticaja klimatskih promena, posebno budućih promena, na ekstremne događaje u Srbiji.
            </p>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/tim">Članovi tima</a></button>
          </div>
        </div>
      </div>




      <div className="container mx-auto px-4 py-8">
      <div className="flex grid grid-cols-1 md:grid-cols-2 gap-8">
    <div className="max-w-xl mx-auto">
    <h2 className="text-4xl font-semibold mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">Metodologija i rezultati</h2>
    <p className="text-lg text-gray-700 text-justify mb-4">
        S obzirom na široki raspon vremenskih i prostornih razmera ekstremnih događaja, kao i na potrebu za obradom velike količine podataka, metodološki pristupi će biti veoma kompleksni i raznovrsni. Jedan od pristupa je identifikacija i analiza značajnih ekstremnih događaja, uz korišćenje sistema klasifikacije sinoptičkih situacija razvijenih za Srbiju, i kroz analizu podataka dobijenih iz osmatranja, kao i rezultata numeričkih simulacija klime. Zatim, detaljna analiza konvektivnih oblaka i mikrofizičkih procesa biće podržana korišćenjem numeričkog modela za simulaciju ovih procesa. Projekat će takođe analizirati i prognozirati ekstremne događaje magle, što uključuje upotrebu regionalnog modela za prognozu vremena. Promene u ekstremima kroz raspodele ključnih promenljivih u budućoj klimi biće posebno analizirane, što će omogućiti bolje razumevanje potencijalnih izmena u intenzitetu i učestalosti ekstremnih vremenskih i klimatskih pojava. U ovoj vrsti analize između ostalog koristiće se i metode mašinskog učenja i veštačke inteligencije. Konačno, razvijanje jedinstvene i kompletne baze podataka sa informacijama o ekstremnim događajima u Srbiji je ključni cilj projekta, koji će podržati različite sektorske studije uticaja i procene rizika.
      </p>
     
    </div>
    <div className="justify-center items-center max-w-xl ">

      <img
        src={image2}
        alt="Project Image"
        className="object-contain max-w-full h-auto max-w-md"
      />
          <p className="text-lg text-gray-700 text-justify">
        Projektni tim će raditi na tome da centralni rezultat projekta bude jedinstvena baza podataka kreirana u obliku koji je lako razumljiv i dostupan za široku upotrebu počevši od naučne zajednice, nacionalnih institucija i lokalnih zajednica pa sve do pojedinačnih korisnika. S obzirom na to da je interpretacija naših rezultata veoma značajna i za širu javnost, diseminacija rezultata projekta kroz letnje škole, seminare, naučno-popularna predavanja, ali i posredstvom medija i društvenih mreža, biće takođe vrlo važna komponenti projekta.
      </p>
    </div>
  </div>
</div>

<Form />
    <Footer />
    </>
  );
}