import React from "react";
import imageLogoInstituta from '../logotip-instituta.png'
import imageLogoFakulteta from '../logotip-fakultet.png'
import imageLogoFondNauke from '../logotip-fond-za-nauku.png'
import imageLogoPrizma from '../logotip-prizma.png'
import sanityClient from "../client";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import gifBaner from '../animated-map.gif';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import SearchComponent from './SearchComponent';
import HeroBannerDesktop from './HeroBanerDesktop';
import { useMediaQuery } from "@uidotdev/usehooks";
import HeroBanerMobile from "./HeroBanerMobile";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import Form from "./Form";
import Footer from "./Footer";

export default function Home() {
  const [vestData, setVest] = useState(null);
    const [reportData, setreportData] = useState(null);
    const [radData, setRad] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");
    useEffect(()=> {
        sanityClient.fetch(`*[_type == "report"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setreportData(data))
        .catch(console.error);
    }, []);

    

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "vest"] | order(publishedAt desc){
                  title,
                  slug,
                  description,
                  publishedAt,
                  mainImage{
                      asset->{
                          _id,
                          url
                      },
                      alt
                  }
              }`
        )
        .then((data) => setVest(data))
        .catch(console.error);
    }, []);

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "rad"] | order(publishedAt desc) {
                  title,
                  slug,
                  description,
                  publishedAt,
                  abstract
              }`
        )
        .then((data) => setRad(data))
        .catch(console.error);
    }, []);
    return (
        <>
        <main>
    {allDevices && (
        <NavBar />
    )}
      {isSmallDevice && (
          <NavBarMobile/>
    )}
        {/* <SearchComponent /> */}
        {allDevices && (
          <HeroBannerDesktop/>
          
        )}
      {isSmallDevice && (
          <HeroBanerMobile/>
          
        )}

            <section className="container max-w-screen-xl flex-col mx-auto py-20 border-b-2 border-gray-100">
                <h2 className="text-4xl text-blue-400 py-5 text-center">Ekstremni vremenski događaji u Srbiji – analiza, modeliranje i uticaji</h2>
                <p className="text-l px-4 text-justify md-px-2">"EXTREMES" se bavi istraživanjem sve češćih i intenzivnijih vremenskih i klimatskih ekstrema, kao što su toplotni talasi, suše, poplave i oluje, koji imaju uticaj na ljude i prirodu i izazivaju štete i gubitke u našoj zelji. Od 2000. ove štete u našoj zemlji su procenjene na preko 7 milijardi evra. Tokom projekta naš tim će koristi savremene i inovativne metodološke pristupe i najnovije tehnologije kako bi objasnio ove događaje, njihov uzrok, analizirao trenutne posledice u moguće rizike u budućnosti.</p>
                <div className="flex justify-center gap-8 py-5">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/ciljevi-projekta">Ciljevi projekta</a></button>
                <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"><a href="/tim">Članovi tima</a></button>
                </div>
            </section>

            {/* <section className="container max-w-screen-xl mx-auto py-20">
        <h2 className="text-4xl text-blue-400 py-5 text-center">
          Extremes novosti
        </h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 md:w-8/12 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {vestData &&
            vestData.slice(0,3).map((vest, index) => (
              <>
              <article>
              <Link to={"/vesti/" + vest.slug.current} key={vest.slug.current}>
                 <Card>
                 <Card.Img variant="top" src={vest.mainImage.asset.url}
                      alt={vest.mainImage.alt} />
                 <Card.Body>
                   <Card.Title className="text-blue-600 text-lg py-2">{vest.title}</Card.Title>
                   <Card.Text>
                   {vest.description}
                   </Card.Text>
                   <Link to={"/vesti/" + vest.slug.current} key={vest.slug.current}>
                   <Button variant="primary" className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">Saznaj više</Button></Link>
                 </Card.Body>
               </Card>
               </Link>
               </article>
               </>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/vesti">Pročitajte sve vesti</a></button>
        </div>
        
        <div className="flex-auto w-100 md:w-4 grid md:grid-cols-1 sm:grid-cols-2 gap-4 ml-4">
        <h2 className="text-4xl text-blue-400 pb-2 text-center">
        Extremes Publikacije
        </h2>
        {radData &&
            radData.map((rad, index) => (
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={rad}>
                    <h3 className="text-blue-600 text-lg py-2 hover:text-blue-700">
                            {rad.title}
                    </h3>
                    <p className="text-gray-800">
                      {new Date(rad.publishedAt).toLocaleDateString()}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{rad.description}</p>
                    <button className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">
                    <Link to={"/radovi/" + rad.slug.current} key={rad.slug.current}>
                            Pročitaj više</Link></button>
                </article>
            ))}
        </div>
        </div>

        
      </section> */}

      <section className="container max-w-screen-xl mx-auto py-20 border-b-2 border-gray-100">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
          EXTREMES novosti
        </h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {vestData &&
            vestData.slice(0,3).map((vest, index) => (
              <>
              <article>
              <Link to={"/vesti/" + vest.slug.current} key={vest.slug.current}>
                 <Card>
                 <Card.Img variant="top" src={vest.mainImage.asset.url}
                      alt={vest.mainImage.alt} />
                 <Card.Body>
                   <Card.Title className="text-blue-600 text-lg py-2">{vest.title}</Card.Title>
                   <Card.Text>
                   {vest.description}
                   </Card.Text>
                   <Link to={"/vesti/" + vest.slug.current} key={vest.slug.current}>
                   <Button variant="primary" className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">Saznaj više</Button></Link>
                 </Card.Body>
               </Card>
               </Link>
               </article>
               </>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/vesti">Pročitajte sve vesti</a></button>
        </div>
        
        </div>

        
      </section>

      <section className="container max-w-screen-xl mx-auto py-20">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
          EXTREMES publikacije
        </h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {radData &&
            radData.slice(0,3).map((rad, index) => (
                    <article className="relative pt-2 pb-4" key={rad}>
                    <h3 className="text-blue-600 text-lg py-2 hover:text-blue-700">
                            {rad.title}
                    </h3>
                    <p className="text-gray-800">
                      {new Date(rad.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{rad.description}</p>
                    <button className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">
                    <Link to={"/radovi/" + rad.slug.current} key={rad.slug.current}>
                            Pročitaj više</Link></button>
                </article>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/naucni-radovi">Pogledajte više</a></button>
        </div>
        
        </div>

        
      </section>

            <section className="container max-w-screen-xl mx-auto py-20 border-t-2 border-gray-100">
                <h2 className="text-4xl text-blue-400 py-5 text-center">Partneri</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-center items-center">
                <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-32 lg:w-48"/>
                </a>
                <a href="http://haos.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoInstituta} alt="Institut za meteorologiju" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/poziv/2022/06/program-prizma/" target="_blank" rel="noopener noreferrer"  className="mx-auto">
                <img src={imageLogoPrizma} alt="Prizma" className="w-32 lg:w-48"/>
                </a>
                </div>
            </section>

            


            
        </main>
        <Form />
    <Footer />
        </>
    )
}