import React from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function WorkPackagesEN() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <div className="container mx-auto px-4 py-8 md:px-10">
    <h2 className="text-2xl mb-4">
    Work Package 1
    </h2>
    <h3 className="text-lg font-semibold mb-2">
Objectives
  </h3>
  <ul className="list-disc pl-4 mb-4">
    <li>
 Identify extreme weather and climate events of interest for Serbia;
    </li>
    <li>
 Collect data needed for analysis and modelling of extreme events.
 </li>
  </ul>
  <h3 className="text-lg font-semibold mb-2">
Description of work and role of the team members: 
  </h3>
  <p className="text-justify mb-4">
    <strong className="font-semibold">
1.1: Extreme events identification</strong>  [Months: 1–5]
  </p>
  <p className="text-justify mb-4">
Through this task, the project team will describe the methodology for extreme events definition and accordingly select extreme events for further analysis. Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Nemanja Kovačević, Irida Lazić, Milica Tošić, and Darko Savić will identify extreme events based on temperature, visibility and precipitation. Vladan Vučković, Dragana Vujović, Nemanja Kovačević, and Darko Savić will identify severe weather events leading to heavy precipitation, severe hail and wind. Extreme climate indices, developed by the joint Expert Team on Climate Change Detection and Indices (ETCCDI), will be selected from the reference list because these indices provide compact information on key extreme events. One of the key approaches of the indices concept involves calculation of the number of days in a year exceeding specific thresholds. For precipitation, the percentile thresholds will be calculated from the sample of all wet days in the base period. For temperature, the percentile thresholds will be calculated on each calendar day to account for the mean annual cycle. Absolute indices, based on the count of days crossing certain fixed thresholds, can be related to extreme events that affect human society and observed impacts, in particular if the thresholds refer to values of physical, hydrological or biological significance. Thermodynamic indices, which measure the ability of the atmosphere to 	develop severe weather or thunderstorm activity, will be selected and calculated from upper air measurements. The ability of convective indices to properly forecast the thunderstorms in the Serbian region will be evaluated.
  </p>
  <p className="text-justify mb-4">
    <strong className="font-semibold">
1.2: Data collection</strong> [Months: 2–9]
 </p>
  <p className="text-justify mb-4">
All team members will collect data necessary for analysis and modelling of extreme events. The project team will determine what types of data will be collected, processed, and generated by the project. The following types of data from publicly available sources will be considered:
</p>
<ul className="list-disc pl-4 mb-4">
    <li>
Meteorological and climatological surface station observations from Yearbooks and radiosonde observations of the Republic Hydrometeorological Service of Serbia. The first step will be assembling available data and selecting the station series for extremes analysis on the basis of series length and data completeness. Then, the quality control procedure will be applied to identify possible errors in observed data.
</li>
<li>
Gridded climatology from Copernicus Climate Change Service and other sources such as DANUBECLIM;
</li>
<li>
Daily and sub-daily reanalysis data sets: National Center for Environmental Prediction/National Center for Atmospheric Research - NCEP/NCAR reanalysis, European Center for Medium Range Weather Forecasts (ECMWF);
</li>
<li>
32-days ECMWF ensemble forecasts and ECMWF analyses;
</li>
<li>
ERA5 and ERA5-Land+ reanalysis, UERRA and CERRA regional analysis from Copernicus Climate Change Service;
</li>
<li>
3-arc-second orography data;
</li>
<li>
Teleconnection indices from the National Oceanic and Atmospheric Administration/Climate Prediction Center.
</li>
</ul>
</div>
<hr className="my-8" />


<div className="container mx-auto px-4 py-8 md:px-10">
  <h2 className="text-2xl mb-4">
Work Package 2 – Analysis of extreme events
  </h2>
  <h3 className="text-lg font-semibold mb-2">

Objectives
</h3>
  <ul className="list-disc pl-4 mb-4">
    <li>

 Analyze extreme events, examining the spatial and temporal variability in the occurrence of extremes, and long-term changes, based on observational data. The aim is to understand both the underlying physics that generate climate and extreme weather events, and the mechanisms by which their statistics vary.
</li>
<li>
 Select most extreme events for detailed analysis.
</li>
</ul>
<h3 className="text-lg font-semibold mb-2">
Description of work and role of the team members: 
</h3>
  <p className="text-justify mb-4">
  <strong className="font-semibold">
2.1: Extreme events analysis</strong> [Months: 6–18]
 </p>
  <p className="text-justify mb-4">
Through this task, the project team will analyse extreme weather and climate events. Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Suzana Putniković, Irida Lazić, and Milica Tošić will analyse extreme events based on temperature, visibility and precipitation. Vladan Vučković, Dragana Vujović, Nemanja Kovačević, and Darko Savić will analyse typical atmospheric conditions favoring formation of thunderstorm and hail through examination of various convective indices which describe state of the atmosphere. The aim is to improve thunderstorm forecast which is still challenging, finding the appropriate thresholds of indices for the Serbian region. Detailed statistical analysis, including long-term variability, frequency, trend, intensity and duration of extreme events, will be made by all project team members. A statistically robust non-parametric method (for instance, Kendall’s Tau) will be applied to investigate trend of extreme events, because the indices generally have non-Gaussian distributions. To understand the causes of extreme events, the relationship with the weather circulation types will be established applying automatic synoptic classiﬁcation systems developed for Serbia. The influence of the large-scale circulation on extreme events will be examined using teleconnection indices (North Atlantic Oscillation, East Atlantic Oscillation, East Atlantic/West Russia pattern, etc.). Irida Lazić and Milica Tošić will classify extreme events and their severity using clustering algorithms.
  </p>
  <p className="text-justify mb-4">
    <strong className="font-semibold">
2.2: Specific high-impact extreme events</strong> [Months: 6–23]
 </p>
  <p className="text-justify mb-4">
The project team will select dates or periods of extreme events for further detailed analysis. The following extreme events will be considered: heat waves, absolute maximum temperatures, drought, consecutive dry days, cold waves, absolute minimum temperatures, frost days, heavy precipitation events, hailstorms, dense fog, etc. All members of the team will analyse synoptic situations, spatial and temporal characteristics of events and their connection with the observed trends in changing climate. From spatial distribution of hail occurrences, significant conclusions about thunderstorm development will be drawn. An analysis of high-impact extreme events will be based on observations and simulations using the advanced and sophisticated numerical models.
  </p>
  <p className="text-justify mb-4">
    <strong className="font-semibold">
2.3: Analysis for extreme events simulation </strong>[Months: 7–12]
 </p>
  <p className="text-justify mb-4">
New 3-arc-second resolution surface elevation data of Serbia will be implemented into the high-resolution mesoscale numerical weather prediction (NWP) model. Topography has a significant influence on thunderstorm formation and regeneration, causing forcing of updrafts and splitting of air flow. Temporal evolution of the storms’ characteristics will be studied to gain knowledge of convective life time and identify parameters that could be used in prediction.
Initialization of the high-resolution mesoscale NWP model will be implemented with fields from a synoptic-scale regional NWP model.
  </p>
</div>
  <><hr className="my-8" />
  <div className="container mx-auto px-4 py-8 md:px-10">
    <h2 className="text-2xl mb-4">
      Work Package 3 – Modelling of extreme events
    </h2>
    <h3 className="text-lg font-semibold mb-2">

      Objectives
    </h3>
    <ul className="list-disc pl-4 mb-4">
      <li>

        This WP focuses on the state of the art in modelling and analysis of extreme events and climate impacts.
      </li>
      <li>
        The aim is to further develop models in order to produce more accurate and reliable simulations and forecast of extreme events.
      </li>
    </ul>
    <h3 className="text-lg font-semibold mb-2">
      Description of work and role of the team members:
    </h3>
    <p className="text-justify mb-4">
      <strong className="font-semibold">
        3.1: Simulations </strong>[Months: 11–30]
    </p>
    <p className="text-justify mb-4">
      Through this task, the project team will simulate extreme events. Vladan Vučković, Dragana Vujović, Nemanja Kovačević and Darko Savić will work on the cloud-resolving model. Nemanja Kovačević will improve the cloud-resolving mesoscale model with lightning discharge parameterization and investigate the relationships among the role of lightning phenomena, torrential rainfall and large hailstones as forms of extreme weather events. Katarina Veljović Koračin will apply the advanced non-hydrostatic Eta atmospheric model in ensemble mode and find the optimal setup for accurate and detailed forecasts of low cloud and fog. Ivana Tošić and Suzana Putniković will use regression models to study how much of the variability in extreme events can be explained by atmospheric circulation patterns. Vladimir Đurđević, Irida Lazić, and Milica Tošić will improve the regional climate model. Dynamical downscaling of the climate projection from at least two global climate models from the CMIP6 database, with the regional climate model EBU-POM will be performed, focusing on the sub-daily model output. Physical storylines of future events comparable to a chosen observed event will be developed to answer what future events might look like. This will help determine different meteorological (dynamical) conditions – drivers for certain extreme events.
    </p>
    <p className="text-justify mb-4">
      <strong className="font-semibold">
        3.2: Models evaluation</strong> [Months: 13–33]
    </p>
    <p className="text-justify mb-4">
      For evaluation of statistical models, model efﬁciency coefﬁcient (MEF), correlation coefﬁcient and coefﬁcient of determination will be applied to assess the predictive power of the regression model.
      A liquid water content (LWC) will be used as a metric for fog forecast verification along with continuous visibility observations.
      Evaluation of regional climate model results for a historical run will be done using different statistical verification scores. Special focus will be on sub-daily statistics for extreme precipitation and wind.
    </p>
    <p className="text-justify mb-4">
      <strong className="font-semibold">
        3.3: Model results analysis</strong> [Months: 20–35]
    </p>
    <p className="text-justify mb-4">
      All project member will analyse the models’ output. A risk analysis will be done following different emission scenarios. The results of the regional climate model will be bias-adjusted on sub-daily time scales. The availability of sub-daily results will ensure analysis of different high-impact extreme events on a wide range of spatial and temporal scales—from sub-daily extreme precipitation and strong winds to changes in frequency and intensity of droughts and heat waves—that have regional spatial scales and time scales from weeks to months.
    </p>
  </div><hr className="my-8" /><div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Work Package 4 – Products and dissemination
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Objectives
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Produce the database with extreme weather and climate events.
        </li>
        <li>
          This WP will ensure dissemination of project results by using the project website, publications, conferences and end-users’ workshop.
        </li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">
        Description of work and role of the team members:
      </h3>
      <p className="text-justify mb-4">
        <strong className="font-semibold">
          4.1: Website </strong>[Months: 4–36] </p>
      <p className="text-justify mb-4">
        Through this task, the project team will update the project website to provide general information on extreme events, on-going activities of the project, selected indices, specific extreme events, and model results. The site will support data analysis activities at a national level.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">
          4.2: Database design </strong>[Months: 7–36] </p>
      <p className="text-justify mb-4">
        Through this task, the project team will update the database with results obtained by data, statistical analysis and modelling.
        Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Suzana Putniković, Irida Lazić, and Milica Tošić will update the database with relevant data and information about extreme events. Vladan Vučković, Dragana Vujović, Nemanja Kovačević, and Darko Savić will update the database containing data on the size, number concentration, mass amount and terminal velocity of precipitation elements, such as raindrops, drizzle, snow, graupel and hailstones. All team members will contribute with research results obtained by weather and climate extreme events simulations, and impact assessment.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">
          4.3: Presentation </strong>[Months: 12–36] </p>
      <p className="text-justify mb-4">
        Through this activity, the project team will present results at international conferences, a workshop, and in publications.
      </p>
    </div><hr className="my-8" /><div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Work Package 5 – Project management
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Objectives
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          The main objective of this work package is to ensure implementation of the project’s work plan and to provide effective project management at all stages of the project.
        </li>
        <li>
          Links with other work packages.
        </li>
      </ul>
      <p className="mb-4">
        WP5 is strongly articulated with the other work packages comprising the work plan, It will ensure that WP coordinators in every task deliver on the relevant management and coordination, including meeting all requirements on reporting, open access, and data management.
      </p>
      <h3 className="text-lg font-semibold mb-2">
        Description of work and role of the team members:
      </h3>
      <p className="mb-4">
        <strong className="font-semibold">
          5.1 Ensure the overall management</strong> [Months: 1–36] </p>
      <p className="text-justify mb-4">
        During the lifetime of the project, Vladimir Đurđević and participants will ensure:
      </p>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Administrative and financial management, assistance for administrative and financial aspects;
        </li>
        <li>
          Overall coordination and communication;
        </li>
        <li>
          Management of the budget.
        </li>
      </ul>
      <p className="text-justify mb-4">
        <strong className="font-semibold">
          5.2 Ensure the scientific coordination </strong>[Months: 1–36] </p>
      <p className="text-justify mb-4">
        Vladimir Đurđević and WP coordinators will:
      </p>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Ensure the coordination of scientific work: meet the deadlines to generate the project’s milestones and deliverables, and take appropriate actions in the case of deviations;
        </li>
        <li>
          Manage the project team in order to follow the execution of the work plan;
        </li>
        <li>
          Deliver the periodic reports and the final report linked to the project execution.
        </li>
      </ul>
    </div><hr className="my-8" /><div className="container mx-auto px-4 py-8 md:px-10">
      <p><em>
        Table 3.2a: List of work packages (WP)
      </em></p>
    </div><div className="container mx-auto px-4 py-8">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr>
              <th className="border px-4 py-2">WP No</th>
              <th className="border px-4 py-2">WP title</th>
              <th className="border px-4 py-2">WP Coordinator - team member’s ID</th>
              <th className="border px-4 py-2">Start month</th>
              <th className="border px-4 py-2">End month</th>
              <th className="border px-4 py-2">Total calendar months of WP duration</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">Extreme events</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">9</td>
              <td className="border px-4 py-2">9</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">1.1</td>
              <td className="border px-4 py-2">Extreme events identification</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">5</td>
              <td className="border px-4 py-2">5</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">1.2</td>
              <td className="border px-4 py-2">Data collection</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">2</td>
              <td className="border px-4 py-2">9</td>
              <td className="border px-4 py-2">8</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">2</td>
              <td className="border px-4 py-2">Analysis of extreme events</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">6</td>
              <td className="border px-4 py-2">23</td>
              <td className="border px-4 py-2">18</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">2.1</td>
              <td className="border px-4 py-2">Extreme events analysis</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">6</td>
              <td className="border px-4 py-2">18</td>
              <td className="border px-4 py-2">13</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">2.2</td>
              <td className="border px-4 py-2">Specific high-impact extreme events</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">6</td>
              <td className="border px-4 py-2">23</td>
              <td className="border px-4 py-2">18</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">2.3</td>
              <td className="border px-4 py-2">Analysis for extreme events simulation</td>
              <td className="border px-4 py-2">Dragana Vujović</td>
              <td className="border px-4 py-2">7</td>
              <td className="border px-4 py-2">12</td>
              <td className="border px-4 py-2">6</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">3</td>
              <td className="border px-4 py-2">Modelling of extreme events</td>
              <td className="border px-4 py-2">Vladimir Đurđević</td>
              <td className="border px-4 py-2">11</td>
              <td className="border px-4 py-2">35</td>
              <td className="border px-4 py-2">25</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">3.1</td>
              <td className="border px-4 py-2">Simulations</td>
              <td className="border px-4 py-2">Vladimir Đurđević</td>
              <td className="border px-4 py-2">11</td>
              <td className="border px-4 py-2">30</td>
              <td className="border px-4 py-2">20</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">3.2</td>
              <td className="border px-4 py-2">Models evaluation</td>
              <td className="border px-4 py-2">Vladimir Đurđević</td>
              <td className="border px-4 py-2">13</td>
              <td className="border px-4 py-2">33</td>
              <td className="border px-4 py-2">21</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">3.3</td>
              <td className="border px-4 py-2">Model results analysis</td>
              <td className="border px-4 py-2">Vladimir Đurđević</td>
              <td className="border px-4 py-2">20</td>
              <td className="border px-4 py-2">35</td>
              <td className="border px-4 py-2">16</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">4</td>
              <td className="border px-4 py-2">Products and dissemination</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">4</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">33</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">4.1</td>
              <td className="border px-4 py-2">Website</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">4</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">33</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">4.2</td>
              <td className="border px-4 py-2">Database design</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">7</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">30</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">4.3</td>
              <td className="border px-4 py-2">Presentation</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">12</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">25</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">5</td>
              <td className="border px-4 py-2">Project management</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">36</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">5.1</td>
              <td className="border px-4 py-2">Ensure the overall management</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">36</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">5.2</td>
              <td className="border px-4 py-2">Ensure the scientific coordination</td>
              <td className="border px-4 py-2">Ivana Tošić</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">36</td>
              <td className="border px-4 py-2">36</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div></>
    <FormEN />
    <FooterEN />
    </>
  );
}
