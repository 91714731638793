import React from "react";
import imageLogoInstituta from "../logotip-instituta.png";
import imageLogoFakulteta from "../logotip-fakultet.png";
import imageLogoFondNauke from "../logotip-fond-za-nauku.png";
import imageLogoPrizma from "../logotip-prizma.png";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

import { SocialIcon } from "react-social-icons";

export default function Kontakt() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
      {allDevices && <NavBar />}
      {isSmallDevice && <NavBarMobile />}
      <div className="flex flex-col lg:flex-row max-w-screen-xl mx-auto">
        <div className="w-full lg:w-1/2 p-4">
          <div className="contact-us-container">
            <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">
              Kontaktirajte nas
            </h1>
            <div className="contact-info">
              <h2>Email</h2>
              <p className="text-lg text-gray-700 text-justify mb-4">
                <a href="mailto:extremes@ff.bg.ac.rs">extremes@ff.bg.ac.rs</a>
              </p>
            </div>
            <div className="contact-info">
              <h2>Adresa</h2>
              <p className="text-lg text-gray-700 text-justify mb-4">
                Dobračina 16, Institut za meteorologiju, Fizički fakultet
                Univerziteta u Beogradu
              </p>
            </div>
            <div className="contact-info">
              <h2>Telefon</h2>
              <p className="text-lg text-gray-700 text-justify mb-4">
                <a href="tel:+381112625981">+381 11 2625 981</a>
              </p>
            </div>

            <div className="inline-flex py-3 px-3 my-6">
              <SocialIcon
                url="https://www.instagram.com/extremesproject/"
                className="mr-4"
                target="_blank"
                bgColor="#000"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
              />
              <SocialIcon
                url="https://twitter.com/EXTREMESproject"
                className="mr-4"
                target="_blank"
                bgColor="#000"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
              />
              <SocialIcon
                url="https://www.linkedin.com/company/extremes-project/"
                className="mr-4"
                target="_blank"
                bgColor="#000"
                fgColor="#fff"
                style={{ height: 35, width: 35 }}
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 p-4">
          <div className="map-container">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.1594107411092!2d20.458770475921504!3d44.81831697630125!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab39afb49a7%3A0x1d8c43947d95fc41!2z0JjQvdGB0YLQuNGC0YPRgiDQt9CwINC80LXRgtC10L7RgNC-0LvQvtCz0LjRmNGDLCBEb2JyYcSNaW5hIDE2LCBCZW9ncmFk!5e0!3m2!1sen!2srs!4v1708686162259!5m2!1sen!2srs"
              width="100%"
              height="300"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
      <section className="container max-w-screen-xl mx-auto py-20 border-t-2 border-gray-100">
        <h2 className="text-4xl text-blue-400 py-5 text-center">Partneri</h2>
        <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-center items-center">
          <a
            href="http://www.ff.bg.ac.rs/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto"
          >
            <img
              src={imageLogoFakulteta}
              alt="Fizički fakultet"
              className="w-32 lg:w-48"
            />
          </a>
          <a
            href="http://haos.ff.bg.ac.rs/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto"
          >
            <img
              src={imageLogoInstituta}
              alt="Institut za meteorologiju"
              className="w-32 lg:w-48"
            />
          </a>
          <a
            href="https://fondzanauku.gov.rs/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto"
          >
            <img
              src={imageLogoFondNauke}
              alt="Fond za nauku"
              className="w-32 lg:w-48"
            />
          </a>
          <a
            href="https://fondzanauku.gov.rs/poziv/2022/06/program-prizma/"
            target="_blank"
            rel="noopener noreferrer"
            className="mx-auto"
          >
            <img src={imageLogoPrizma} alt="Prizma" className="w-32 lg:w-48" />
          </a>
        </div>
      </section>

      <Form />
      <Footer />
    </>
  );
}
