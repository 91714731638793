import React from "react";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";
import imageSuse from "../catalog/Suse.png";

export default function KatalogEN() {
  const isSmallDevice = useMediaQuery("only screen and (max-width: 768px)");
  const allDevices = useMediaQuery("only screen and (min-width: 769px)");

  return (
    <>
      {allDevices && <NavBarEN />}
      {isSmallDevice && <NavBarMobileEN />}
      <div
        style={{
          overflowX: "hidden",
          boxSizing: "border-box",
        }}
        className="container mx-auto text-center min-h-screen max-w-screen-xl"
      >
        <div className="flex justify-start items-center">
          <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 pb-2 text-center lg:text-left">
            CATALOG OF WEATHER AND CLIMATE EXTREMES IN SERBIA
          </h1>
        </div>
        <div className="mx-auto text-left">
          <hr />
          <p className="px-5 sm:px-0 pt-5 sm:pt-0 text-justify sm:text-left">
            Introductory Note: The EXTREMES catalog will be regularly updated
            during the project implementation period, so relatively frequent
            changes and additions to the content, presented information, images,
            and graphics are possible. In this regard, we encourage all users of
            the catalog to periodically check for updates to ensure access to
            the latest information.
          </p>
          <br />
        </div>

        <div className="wrapper max-w-screen-xl mx-auto">
          <a href="/suse">
            <div className="deoKatalog">
              <div className="textKatalog">
                <h2>DROUGHTS</h2>
                <p>
                  Observed data and <br />
                  future projections and trends
                </p>
              </div>
              <img src={imageSuse} alt="Droughts" />
            </div>
          </a>
          <div class="deoKatalog hidden1">
            <div class="textKatalog">
              <h2>SUŠE</h2>
              <p>
                Text text
                <br />
                Text text
              </p>
            </div>
            <img src={imageSuse} />
          </div>
          <div class="deoKatalog hidden1">
            <div class="textKatalog">
              <h2>SUŠE</h2>
              <p>
                Text text
                <br />
                Text text
              </p>
            </div>
            <img src={imageSuse} />
          </div>
          <div class="deoKatalog hidden1">
            <div class="textKatalog">
              <h2>SUŠE</h2>
              <p>
                Text text
                <br />
                Text text
              </p>
            </div>
            <img src={imageSuse} />
          </div>
          <div class="deoKatalog hidden1">
            <div class="textKatalog">
              <h2>SUŠE</h2>
              <p>
                Text text
                <br />
                Text text
              </p>
            </div>
            <img src={imageSuse} />
          </div>
          <div class="deoKatalog hidden1">
            <div class="textKatalog">
              <h2>SUŠE</h2>
              <p>
                Text text
                <br />
                Text text
              </p>
            </div>
            <img src={imageSuse} />
          </div>
        </div>
      </div>
      <FormEN />
      <FooterEN />
    </>
  );
}
