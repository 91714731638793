import React from 'react';
import gifBaner from '../sporije.gif';
//import imageLogo from "../razdvojeno.png";
//import imageLogo from "../extremesHorizontalnoVeliki.png"



const HeroBanerDesktop = () => {
  return (
    <>
      <section class="h-[600px] w-[600px] relative">
        <img src={gifBaner} alt="Extremes Project" />
        <div class="absolute top-24 px-4 py-3 w-full text-center flex justify-center">
            <h1 className="text-center text-black-100 leading-none lg:leading-snug home-name"> 
            {/* <img src={imageLogo} alt="Extremes project" className="w-3/6 lg:w-3/6 mx-auto"/> */}
            </h1>
        </div>
      </section>
    </>
  )
}

export default HeroBanerDesktop