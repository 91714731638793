import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SinglePost() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();


  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            autor,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) return <div>Loading...</div>;

  return (
    <>
    {allDevices && (
    <NavBar />
)}
  {isSmallDevice && (
      <NavBarMobile/>
)}
    <main className="bg-white-100 min-h-screen px-4 md:p-12">
      <article className="container shadow-lg mx-auto bg-white-100 rounded-lg">
        <header className="relative">
          <div className="absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-white bg-opacity-75 rounded p-12">
              <h1 className="text-2xl lg:text-2xl mb-4">
                {singlePost.title}
              </h1>

            </div>
          </div>
          <img
            src={singlePost.mainImage.asset.url}
            alt={singlePost.title}
            className="w-full object-cover rounded-t"
            style={{ height: "400px" }}
          />
        </header>
        <div className="px-4 py-28 md:px-16 lg:px-48 py-12 lg:py-20 max-w-full">
          <BlockContent
            blocks={singlePost.body}
            projectId="krjmu28b"
            dataset="production"
          />
          <p className="text-gray-800 mb-2 mt-4">Autor: {singlePost.autor}</p>
        </div>
      </article>
    </main>
    <Form />
    <Footer />
    </>
  );
}