import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

export default function Vest() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  const [vestData, setVest] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "vest"] | order(publishedAt desc){
                title,
                slug,
                description,
                publishedAt,
                autor,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                }
            }`
      )
      .then((data) => setVest(data))
      .catch(console.error);
  }, []);

  return (
    <>
                {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container max-w-screen-md mx-auto">
        <h1 className="text-4xl flex justify-center pb-4">
          EXTREMES Vesti
        </h1>
        <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-8">
          {vestData &&
            vestData.map((vest, index) => (
              <article>
                <Link to={"/vesti/" + vest.slug.current} key={vest.slug.current}>
                  <span
                    className="block relative rounded shadow leading-snug bg-white border-2"
                    key={index}
                  >
                    <div className="grid md:grid-cols-2 lg:grid-cols-2">
                    <img
                      src={vest.mainImage.asset.url}
                      alt={vest.mainImage.alt}
                      className="w-full h-full rounded-r"
                    />

                <span className="block relative h-full flex flex-col pt-4 px-4 pb-4">
                      <p className="text-gray-800 px-2 py-4">
                      {new Date(vest.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                      <h3 className="text-blue-600 text-lg font-blog px-2 py-2">
                        {vest.title}
                      </h3>
                      <p className="text-gray-800 px-2 py-2">
                      {vest.description}
                      </p>
                      <p className="text-gray-800 px-2 py-2">
                      Autor: {vest.autor}
                      </p>
                    </span>
                    </div>
                  </span>
                </Link>
              </article>
            ))}
        </div>
      </section>
    </main>
    <Form />
    <Footer />
    </>
  );
}