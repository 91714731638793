import React from "react";
import logo from '../extremes-white.png';
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
import { SocialIcon } from "react-social-icons";


const Footer = () => {
    return (
        <>
        <Box>
            <FooterContainer>
                <Row>
                    <Column>
                        <img src={logo} alt="Extremes Project" class="w-40"></img>
                    </Column>
                    <Column>
                        <Heading>EXTREMES</Heading>
                        <FooterLink href="/o-projektu">
                            O projektu
                        </FooterLink>
                        <FooterLink href="/tim">
                            Članovi tima
                        </FooterLink>
                        <FooterLink href="/work-packages">
                            Work Packages
                        </FooterLink>
                        <FooterLink href="/kontakt">
                            Kontakt
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Novosti</Heading>
                        <FooterLink href="/vesti">
                            Vesti
                        </FooterLink>
                        <FooterLink href="/blog">
                        Blog
                        </FooterLink>
                        <FooterLink href="/naucni-radovi">
                        Naučni radovi
                        </FooterLink>
                        <FooterLink href="/izvestaji">
                        Tehnički izveštaji
                        </FooterLink>
                        <FooterLink href="/katalog">
                        Katalog
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Zapratite nas!</Heading>
                        <div className="inline-flex justify-start py-6">
                <SocialIcon url="https://www.instagram.com/extremesproject/" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />
                <SocialIcon url="https://twitter.com/EXTREMESproject" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />
                <SocialIcon url="https://www.linkedin.com/company/extremes-project/" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />

            </div>
                    </Column>
                </Row>
            </FooterContainer>

            
        </Box>
        <p className="text-center p-2">© Extremes Project. Omogućeno zahvaljujući <a className="text-blue-600" href="https://www.bazarko.rs">Bazarko.rs</a></p>
        </>
    );
};
export default Footer;