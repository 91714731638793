import { useState } from "react"; // import state
import logoExtremes from "../extremesHorizontalnoVeliki.png"

export default function NavEN() {
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false

  return (
    <div className="flex items-center justify-between py-2">
      <a href="/en">
        <img src={logoExtremes}  alt="Extremes Project" className="w-60"/>
      </a>
      <nav>
        <section className="MOBILE-MENU flex lg:hidden">
          <div
            className="HAMBURGER-ICON space-y-2"
            onClick={() => setIsNavOpen((prev) => !prev)} // toggle isNavOpen state on click
          >
            <span className="block h-0.5 w-8 animate-pulse bg-gray-900"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-900"></span>
            <span className="block h-0.5 w-8 animate-pulse bg-gray-900"></span>
          </div>

          <div className={isNavOpen ? "showMenuNav" : "hideMenuNav"}> 
            <div
              className="CROSS-ICON absolute top-0 right-0 px-8 py-8"
              onClick={() => setIsNavOpen(false)} // change isNavOpen state to false to close the menu
            >
              <svg
                className="h-8 w-8 text-black-100"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
            <ul className="MENU-LINK-MOBILE-OPEN flex flex-col items-center justify-between min-h-[250px]">
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/about-project">Extremes</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/goals-of-project">Project goals</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/team">Team members</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/work-packages">Work packages</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/publications">Publications</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/scientific-papers">Scientific papers</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/reports">Technical reports</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/news">News</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/blog">Blog</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/upcoming-events">Upcoming events</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/catalog">Catalog</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/en/contact">Contact</a>
              </li>
              <li className="border-b border-gray-400 uppercase mb-2">
                <a href="/">RS</a>
              </li>
            </ul>
          </div>
        </section>

        <ul className="DESKTOP-MENU hidden space-x-8 lg:flex">
          <li>
            <a href="/about">About</a>
          </li>
          <li>
            <a href="/portfolio">Portfolio</a>
          </li>
          <li>
            <a href="/contact">Contact</a>
          </li>
        </ul>
      </nav>
      <style>{`
      .hideMenuNav {
        display: none;
      }
      .showMenuNav {
        display: block;
        position: absolute;
        width: 100%;
        height: 100vh;
        top: 0;
        left: 0;
        background: white;
        z-index: 10;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
      }
    `}</style>
    </div>
  );
}