import React from "react";
import logo from '../extremes-white.png';
import {
    Box,
    FooterContainer,
    Row,
    Column,
    FooterLink,
    Heading,
} from "./FooterStyles";
import { SocialIcon } from "react-social-icons";


const FooterEN = () => {
    return (
        <>
        <Box>
            <FooterContainer>
                <Row>
                    <Column>
                        <img src={logo} alt="Extremes Project" class="w-40"></img>
                    </Column>
                    <Column>
                        <Heading>EXTREMES</Heading>
                        <FooterLink href="/en/about-project">
                            About project
                        </FooterLink>
                        <FooterLink href="/en/team">
                            Team members
                        </FooterLink>
                        <FooterLink href="/en/work-packages">
                            Work Packages
                        </FooterLink>
                        <FooterLink href="/en/contact">
                            Contact
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>News</Heading>
                        <FooterLink href="/en/news">
                            News
                        </FooterLink>
                        <FooterLink href="/en/blog">
                        Blog
                        </FooterLink>
                        <FooterLink href="/en/scientific-papers">
                        Scientific papers
                        </FooterLink>
                        <FooterLink href="/en/reports">
                        Tehnical reports
                        </FooterLink>
                        <FooterLink href="/en/catalog">
                        Catalog
                        </FooterLink>
                    </Column>
                    <Column>
                        <Heading>Follow us!</Heading>
                        <div className="inline-flex justify-start py-6">
                <SocialIcon url="https://www.instagram.com/extremesproject/" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />
                <SocialIcon url="https://twitter.com/EXTREMESproject" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />
                <SocialIcon url="https://www.linkedin.com/company/extremes-project/" className="mr-4" target="_blank" bgColor="#FFF" fgColor="#000" style={{height:35 , width: 35}} />

            </div>
                    </Column>
                </Row>
            </FooterContainer>

            
        </Box>
        <p className="text-center p-2">© Extremes Project. Powered by <a className="text-blue-600" href="https://www.bazarko.rs">Bazarko.rs</a></p>
        </>
    );
};
export default FooterEN;