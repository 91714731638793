import React from 'react';
import gifBaner from '../mob-extremes-weather.gif';
//import imageLogo from "../extremesHorizontalnoVeliki.png";
import { NavLink } from "react-router-dom";
import logoExtremes from '../logo_extremes_veliki_bw.png';
import imageLogoFakulteta from '../logotip-fakultet.png';
import imageLogoFondNauke from '../logotip-fond-za-nauku.png';

const HeroBanerMobile = () => {
  return (
    <>
      <section class="h-700 relative">
        <img src={gifBaner} alt="Extremes Project" />
        <div class="absolute top-24 px-4 py-3 w-full text-center flex justify-center">
            <h1 className="text-center text-black-100 leading-none lg:leading-snug home-name"> 
            {/* <img src={imageLogo} alt="Extremes project" className="w-100 mx-auto"/> */}
            </h1>
        </div>
      </section>
      <div className="container mx-auto flex-col justify-between">

      <div className="flex justify-center text-center py-2">
      <NavLink to="/" exact 
                activeClassName="text-white"
                className="inline-flex items-center px-3 mx-auto text-4xl font-bold cursive tracking-widest">
                  <img src={logoExtremes} alt="Extremes Project" className="w-20"/>
                </NavLink>
      <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-20"/>
              </a>
      <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-20"/>
            </a>
          </div>
          </div>
    </>
  )
}

export default HeroBanerMobile;