import React from "react";
import Form from "./Form";
import Footer from "./Footer";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";

export default function WorkPackages() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
                {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
    <div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Radni paket 1 – Ekstremni događaji
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Ciljevi
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Identifikovati ekstremne vremenske i klimatske događaje od interesa za Srbiju;
        </li>
        <li>
          Prikupiti podatke potrebne za analizu i modeliranje ekstremnih događaja.
        </li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">
        Opis posla i uloga članova tima:
      </h3>
      <p className="text-justify mb-4">
        <strong className="font-semibold">1.1: Identifikacija ekstremnih događaja</strong> [meseci: 1–5]
      </p>
      <p className="text-justify mb-4">
        Kroz ovaj zadatak, projektni tim će opisati metodologiju za definisanje ekstremnih događaja i shodno tome odabrati ekstremne događaje za dalju analizu. Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Nemanja Kovačević, Irida Lazić, Milica Tošić, Darko Savić identifikovaće ekstremne događaje na osnovu temperature, vidljivosti i padavina. Vladan Vučković, Dragana Vujović, Nemanja Kovačević i Darko Savić identifikovaće jake vremenske pojave koje su dovele do obilnih padavina, jakog grada i vetra. Indeksi klimatskih ekstrema, koje je razvio Ekspertski tim za proučavanje klimatskih promena i klimatskih indeksa (Expert Team on Climate Change Detection and Indices - ETCCDI), biće izabrani sa referentne liste, s obzirom na to da ovi indeksi pružaju kompletne informacije o ključnim ekstremnim događajima. Jedan od ključnih pristupa koncepta klimatskih indeksa uključuje izračunavanje broja dana u godini koji prelaze određene pragove. Za padavine, percentilski pragovi će se izračunati iz uzorka svih dana sa padavinama u baznom periodu. Za temperaturu, perecentilski pragovi će se izračunavati svakog kalendarskog dana da bi se uračunao srednji godišnji ciklus. Apsolutni indeksi, zasnovani na broju dana koji prelaze određene fiksne pragove, mogu se povezati sa ekstremnim događajima koji utiču na društvoi ekosistem, kao i osmotrenim uticajima, posebno ako se pragovi odnose na vrednosti od fizičkog, hidrološkog ili biološkog značaja. Termodinamički indeksi, koji mere kapacitet atmosfere da razvije opasne vremenske prilike i grmljavinsku aktivnost, biće odabrani i izračunati na osnovu merenja u višim slojevima atmosfere. Biće ocenjena efikasnost konvektivnih indeksa da pravilno predvide grmljavinske nepogode na području Srbije.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">1.2: Prikupljanje podataka</strong> [meseci: 2–9]
      </p>
      <p className="text-justify mb-4">
        Svi članovi tima će prikupljati podatke neophodne za analizu i modeliranje ekstremnih događaja. Projektni tim će odrediti koje vrste podataka će biti prikupljene, obrađivane i generisane. U obzir će se uzeti sledeće vrste podataka iz javno dostupnih izvora:
      </p>
      <ul className="list-disc pl-8 mb-4">
        <li>
          Meteorološka i klimatološka osmatranja površinskih stanica iz Godišnjaka i osmatranja radiosondom Republičkog hidrometeorološkog zavoda Srbije. Prvi korak će biti prikupljanje dostupnih podataka i odabir vremenske serije stanica za analizu ekstrema na osnovu dužine serije i kompletnosti podataka. Zatim će se primeniti procedura kontrole kvaliteta da bi se identifikovale moguće greške u osmotrenim podacima.
        </li>
        <li>
          Klimatološki podaci sa pravilne mreže tačaka iz Copernicus Climate Change Service - C3S i drugih izvora kao što je DANUBECLIM;
        </li>
        <li>
          Dnevni i satni skupovi podataka dobijeni iz reanalize: <strong>N</strong>ational <strong>C</strong>enter for <strong>E</strong>nvironmental <strong>P</strong>rediction/<strong>N</strong>ational <strong>C</strong>enter for <strong>A</strong>tmospheric <strong>R</strong>esearch - NCEP/NCAR reanaliza, <strong>E</strong>uropean <strong>C</strong>entre for <strong>M</strong>edium-<strong>R</strong>ange <strong>W</strong>eather <strong>F</strong>orecasts (ECMWF);
        </li>
        <li>
          32-dnevne ECMWF ansambl prognoze i ECMWF analize;
        </li>
        <li>
          ERA5 i ERA5-Land+ reanaliza, UERRA i CERRA regionalna analiza od Copernicus Climate Change Service;
        </li>
        <li>
          Podaci o orografiji od 3 lučne sekunde;
        </li>
        <li>
          Indeksi telekonekcije iz <strong>N</strong>ational <strong>O</strong>ceanic and <strong>A</strong>tmospheric <strong>A</strong>dministration/Climate Prediction Center (NOAA).
        </li>
      </ul>
    </div>
    <hr className="my-8" />
    
    
    
    <div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Radni paket 2 – Analiza ekstremnih događaja
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Ciljevi
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Analizirati ekstremne događaje, ispitujući prostornu i vremensku varijabilnost u pojavi ekstrema, i dugoročne promene, na osnovu osmotrenih podataka. Cilj je da se razumeju fizički mehanizmi klimatskog sistema i ekstremnih vremenskih prilika, kao i njihove promene u prostoru i vremenu.
        </li>
        <li>
          Izabrati najekstremnije događaje za detaljnu analizu.
        </li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">
        Opis posla i uloga članova tima:
      </h3>
      <p className="text-justify mb-4">
        <strong className="font-semibold">2.1: Analiza ekstremnih događaja</strong> [meseci: 6–18]
      </p>
      <p className="text-justify mb-4">
        Kroz ovaj zadatak, projektni tim će analizirati ekstremne vremenske i klimatske događaje. Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Suzana Putniković, Irida Lazić i Milica Tošić analiziraće ekstremne događaje na osnovu temperature, vidljivosti i padavina. Vladan Vučković, Dragana Vujović, Nemanja Kovačević i Darko Savić analiziraće tipične atmosferske uslove koji pogoduju formiranju grmljavinske nepogode i grada kroz ispitivanje različitih konvektivnih indeksa koji opisuju stanje atmosfere. Cilj je da se poboljša prognoziranje grmljavinske nepogode, koje u meteorologiji i dalje predstavlja veliki izazov, pronalaženje odgovarajućih pragova indeksa za područje Srbije. Detaljnu statističku analizu, uključujući ispitivanje dugoročne varijabilnosti, učestalosti, trenda, intenziteta i trajanja ekstremnih vremenskih i klimatskih događaja, uradiće svi članovi projektnog tima. Statistički robustan neparametarski test (na primer, Kendalov Tau) biće primenjen za istraživanje trenda ekstremnih događaja, jer indeksi ekstremnih događaja generalno nemaju Gausovu raspodelu. Da bi se razumeli uzroci ekstremnih događaja, proceniće se veza sa tipovima atmosferske cirkulacije primenom automatskih sinoptičkih sistema klasifikacije razvijenih za Srbiju. Uticaj cirkulacije velikih razmera na ekstremne događaje biće ispitan korišćenjem indeksa telekonekcije (Severnoatlantska oscilacija, Istočnoatlantska oscilacija, obrazac Istočni Atlantik/Zapadna Rusija, itd.). Irida Lazić i Milica Tošić će klasifikovati ekstremne događaje i njihovu jačinu koristeći algoritame klasterovanja.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">2.2: Specifični ekstremni događaji velikog uticaja</strong> [meseci: 6–23]
      </p>
      <p className="text-justify mb-4">
        Projektni tim će izabrati datume ili periode ekstremnih događaja za dalju detaljnu analizu. U obzir će se uzeti sledeći ekstremni događaji: toplotni talasi, apsolutne maksimalne temperature, suša, uzastopni sušni dani, talasi hladnoće, apsolutne minimalne temperature, mrazni dani, jake padavine, grad, gusta magla, itd. Svi članovi tima će analizirati sinoptičke situacije, prostorne i vremenske karakteristike događaja i njihovu povezanost sa uočenim trendovima u promeni klime. Iz prostorne raspodele pojave grada izvući će se značajni zaključci o razvoju grmljavinskih nepogoda. Analiza ekstremnih događaja sa velikim uticajem, biće zasnovana na osmatranjima i simulacijama naprednih i sofisticiranih numeričkih modela.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">2.3: Analiza simulacije ekstremnih događaja</strong> [Meseci: 7–12]
      </p>
      <p className="text-justify">
        Novi podaci o nadmorskoj visini površine Srbije u rezoluciji 3 lučne sekunde biće implementirani u mezorazmerni model numeričke prognoze vremena visoke rezolucije. Orografija ima značajan uticaj na formiranje i regeneraciju grmljavinske nepogode, uzrokujući forsiranje uzlaznih strujanja i razdvajanje toka vazduha. Proučavaće se vremenska evolucija karakteristika oluja kako bi se steklo znanje o životnom veku nepogode i identifikovali parametri koji bi se mogli koristiti u prognoziranju. Inicijalizacija mezorazmernog modela visoke rezolucije biće sprovedena uz pomoć polja iz regionalnog modela sinoptičkih razmera.
      </p>
    </div>
    <hr className="my-8" />
    
    <div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Radni paket 3 – Modeliranje ekstremnih događaja
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Ciljevi
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Ovaj radni paket se fokusira na najsavremenije modele i analize ekstremnih događaja i uticaja klimatskih promena.
        </li>
        <li>
          Cilj je da se dalje razvijaju modeli za preciznije i pouzdanije simulacije i prognoze ekstremnih događaja.
        </li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">
        Opis posla i uloga članova tima:
      </h3>
      <p className="text-justify mb-4">
        <strong className="font-semibold">3.1: Simulacije</strong> [meseci: 11–30]
      </p>
      <p className="text-justify mb-4">
        Kroz ovaj zadatak, projektni tim će simulirati ekstremne događaje. Na numeričkom modelu oblaka radiće Vladan Vučković, Dragana Vujović, Nemanja Kovačević i Darko Savić. Nemanja Kovačević će poboljšati mezorazmerni numerički model oblaka sa parametrizacijom električnih pražnjenja i istražiti odnose između uloge munja, bujičnih padavina i velikog grada kao oblika ekstremnih vremenskih pojava. Katarina Veljović Koračin će primeniti napredni nehidrostatski Eta atmosferski model u ansambl režimu i pronaći optimalnu postavku za tačne i detaljne prognoze niske oblačnosti i magle. Ivana Tošić i Suzana Putniković koristiće regresione modele da prouče koliki se deo varijabilnosti u ekstremnim događajima može objasniti obrascima atmosferske cirkulacije. Vladimir Đurđević, Irida Lazić i Milica Tošić unaprediće regionalni klimatski model. Izvršiće se dinamička regionalizacija klime iz najmanje dva globalna klimatska modela iz baze podataka CMIP6, regionalnim klimatskim modelom EBU-POM, sa fokusom na dnevni/satni rezultat modela. “Storylines” budućih događaja uporedivih sa odabranim osmatranim ekstremnim vremenskim ili klimatskim događajem biće razvijene kako bi se odgovorilo kako bi budući događaji mogli izgledati. Ovo će pomoći u određivanju različitih meteoroloških (dinamičkih i termodinamičkih) uslova – pokretača određenih ekstremnih događaja.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">3.2: Evaluacija modela</strong> [meseci: 13–33]
      </p>
      <p className="text-justify mb-4">
        Za procenu statističkih modela, koeficijent efikasnosti modela (MEF), koeficijent korelacije i koeficijent determinacije biće primenjeni za procenu prediktivne moći regresionog modela. Sadržaj tečne vode će se koristiti kao metrika za verifikaciju prognoze magle zajedno sa kontinuiranim osmatranjem vidljivosti. Evaluacija rezultata regionalnog klimatskog modela za istorijsku simulaciju biće obavljena korišćenjem različitih statističkih rezultata verifikacije. Poseban fokus biće na satnim statistikama za ekstremne padavine i vetar.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">3.3: Analiza rezultata modela</strong> [Meseci: 20–35]
      </p>
      <p className="text-justify">
        Svi članovi projekta će analizirati rezultate modela. Analiza rizika će biti urađena prema različitim scenarijima emisije. Greška (“bias”) u rezultatima regionalnog klimatskog modela biće korigovana. Dostupnost satnih rezultata će obezbediti analizu različitih ekstremnih događaja sa velikim uticajem na širokom spektru prostornih i vremenskih razmera – od ekstremnih padavina i jakih vetrova na dnevnom nivou do promena u učestalosti i intenzitetu suša i toplotnih talasa – koji imaju regionalne prostorne razmere i vremenske razmere od nekoliko nedelja do nekoliko meseci.
      </p>
    </div>
    <hr className="my-8" />
    
    
    <div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">
        Radni paket 4 – Produkti i diseminacija
      </h2>
      <h3 className="text-lg font-semibold mb-2">
        Ciljevi
      </h3>
      <ul className="list-disc pl-4 mb-4">
        <li>
          Napraviti bazu podataka sa ekstremnim vremenskim i klimatskim događajima.
        </li>
        <li>
          Ovaj radni paket će obezbediti diseminaciju rezultata projekta korišćenjem web stranice projekta, publikacija, konferencija i radionica za krajnje korisnike.
        </li>
      </ul>
      <h3 className="text-lg font-semibold mb-2">
        Opis posla i uloga članova tima:
      </h3>
      <p className="text-justify mb-4">
        <strong className="font-semibold">4.1: Web sajt</strong> [meseci: 4–36]
      </p>
      <p className="text-justify mb-4">
        Kroz ovaj zadatak, projektni tim će formirati i ažurirati web stranicu projekta kako bi pružio opšte informacije o ekstremnim događajima, tekućim aktivnostima projekta, odabranim indeksima, specifičnim ekstremnim događajima i rezultatima modela. Sajt će podržati aktivnosti analize podataka na nacionalnom nivou.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">4.2: Dizajn baze podataka</strong> [meseci: 7–36]
      </p>
      <p className="text-justify mb-4">
        Projektni tim će kroz ovaj zadatak ažurirati bazu podataka rezultatima dobijenim podacima, statističkom analizom i modeliranjem. Vladimir Đurđević, Ivana Tošić, Katarina Veljović Koračin, Suzana Putniković, Irida Lazić i Milica Tošić ažuriraće bazu relevantnim podacima i informacijama o ekstremnim događajima. Vladan Vučković, Dragana Vujović, Nemanja Kovačević i Darko Savić ažuriraće bazu podataka koja sadrži podatke o veličini, koncentraciji, masi i terminalnoj brzini padavina, kao što su kišne kapi, rosulja, sneg, graupel i grad. Svi članovi tima će doprineti rezultatima istraživanja dobijenim simulacijama vremenskih i klimatskih ekstremnih događaja i procenom uticaja.
      </p>
      <p className="text-justify mb-4">
        <strong className="font-semibold">4.3: Prezentacija</strong> [meseci: 12–36]
      </p>
      <p className="text-justify">
        Kroz ovu aktivnost projektni tim će prezentovati rezultate na međunarodnim konferencijama, radionicama i u naučnim publikacijama.
      </p>
    </div>
    <hr className="my-8" />
    
    <div className="container mx-auto px-4 py-8 md:px-10">
      <h2 className="text-2xl mb-4">Radni paket 5 – Upravljanje projektom</h2>
      <h3 className="text-lg font-semibold mb-2">Ciljevi</h3>
      <ul className="list-disc pl-4 mb-4">
        <li>Glavni cilj ovog radnog paketa je da obezbedi sprovođenje plana rada projekta i da obezbedi efikasno upravljanje projektom u svim fazama projekta.</li>
        <li>Veze sa drugim radnim paketima.</li>
      </ul>
      <p className="mb-4">Radni paket 5 je čvrsto povezan sa drugim radnim paketima koji čine radni plan. Njime će se osigurati da koordinatori radnih paketa u svakom zadatku ispunjavaju relevantne zadatke upravljanja i koordinacije, uključujući ispunjavanje svih zahteva u vezi sa izveštavanjem, otvorenim pristupom i upravljanjem podacima.</p>
      <h3 className="text-lg font-semibold mb-2">Opis posla i uloga članova tima:</h3>
      <p className="mb-4">
        <strong className="font-semibold">5.1 Sveukupno upravljanje</strong> [Meseci: 1–36]
      </p>
      <p className="mb-4">
        Tokom trajanja projekta, Vladimir Đurđević i učesnici će obezbediti:
      </p>
      <ul className="list-disc pl-4 mb-4">
        <li>Administrativno i finansijsko upravljanje, pomoć za administrativne i finansijske aspekte;</li>
        <li>Ukupna koordinacija i komunikacija;</li>
        <li>Upravljanje budžetom.</li>
      </ul>
      <p className="mb-4">
        <strong className="font-semibold">5.2 Naučna koordinacija</strong> [Meseci: 1–36]
      </p>
      <p>
        Vladimir Đurđević i koordinatori radnih paketa će:
      </p>
      <ul className="list-disc pl-4 mb-4">
        <li>Osigurati koordinaciju naučnog rada: ispuniti rokove za generisanje ključnih tačaka projekta i dostavljanje rezultata, te preduzeti odgovarajuće akcije u slučaju odstupanja od plana;</li>
        <li>Upravljati projektnim timom u cilju praćenja izvršenja plana rada;</li>
        <li>Dostaviti periodične izveštaje i završni izveštaj u vezi sa izvođenjem projekta.</li>
      </ul>
    </div>
    <hr className="my-8" />
    
    <div className="container mx-auto px-4 py-8 md:px-10">
    <p><em>Tabela 3.2a: Lista radnih paketa:</em></p>
    </div>
    <div className="container mx-auto px-4 py-8">
      <div className="overflow-x-auto">
        <table className="table-auto w-full border-collapse">
          <thead>
            <tr>
              <th className="border px-4 py-2">Broj</th>
              <th className="border px-4 py-2">Ime radnog paketa</th>
              <th className="border px-4 py-2">Koordinator radnog paketa</th>
              <th className="border px-4 py-2">Početak rada (meseci)</th>
              <th className="border px-4 py-2">Kraj rada (meseci)</th>
              <th className="border px-4 py-2">Ukupan broj meseci trajanja radnog paketa</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">Ekstremni događaji</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">9</td>
              <td className="border px-4 py-2">9</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">1.1</td>
              <td className="border px-4 py-2">Identifikacija ekstremnih događaja</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">1</td>
              <td className="border px-4 py-2">5</td>
              <td className="border px-4 py-2">5</td>
            </tr>
            <tr>
              <td className="border px-4 py-2">1.2</td>
              <td className="border px-4 py-2">Prikupljanje podataka</td>
              <td className="border px-4 py-2">Vladan Vučković</td>
              <td className="border px-4 py-2">2</td>
              <td className="border px-4 py-2">9</td>
              <td className="border px-4 py-2">8</td>
            </tr>
            <tr>
      <td className="border px-4 py-2">2</td>
      <td className="border px-4 py-2">Analiza ekstremnih događaja</td>
      <td className="border px-4 py-2">Dragana Vujović</td>
      <td className="border px-4 py-2">6</td>
      <td className="border px-4 py-2">23</td>
      <td className="border px-4 py-2">18</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">2.1</td>
      <td className="border px-4 py-2">Analiza ekstremnih događaja</td>
      <td className="border px-4 py-2">Dragana Vujović</td>
      <td className="border px-4 py-2">6</td>
      <td className="border px-4 py-2">18</td>
      <td className="border px-4 py-2">13</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">2.2</td>
      <td className="border px-4 py-2">Specifični ekstremni događaji velikog uticaja</td>
      <td className="border px-4 py-2">Dragana Vujović</td>
      <td className="border px-4 py-2">6</td>
      <td className="border px-4 py-2">23</td>
      <td className="border px-4 py-2">18</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">2.3</td>
      <td className="border px-4 py-2">Analiza simulacije ekstremnih događaja</td>
      <td className="border px-4 py-2">Dragana Vujović</td>
      <td className="border px-4 py-2">7</td>
      <td className="border px-4 py-2">12</td>
      <td className="border px-4 py-2">6</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">3</td>
      <td className="border px-4 py-2">Modeliranje ekstremnih događaja</td>
      <td className="border px-4 py-2">Vladimir Đurđević</td>
      <td className="border px-4 py-2">11</td>
      <td className="border px-4 py-2">35</td>
      <td className="border px-4 py-2">25</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">3.1</td>
      <td className="border px-4 py-2">Simulacije</td>
      <td className="border px-4 py-2">Vladimir Đurđević</td>
      <td className="border px-4 py-2">11</td>
      <td className="border px-4 py-2">30</td>
      <td className="border px-4 py-2">20</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">3.2</td>
      <td className="border px-4 py-2">Evaluacija modela</td>
      <td className="border px-4 py-2">Vladimir Đurđević</td>
      <td className="border px-4 py-2">13</td>
      <td className="border px-4 py-2">33</td>
      <td className="border px-4 py-2">21</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">3.3</td>
      <td className="border px-4 py-2">Analiza rezultata modela</td>
      <td className="border px-4 py-2">Vladimir Đurđević</td>
      <td className="border px-4 py-2">20</td>
      <td className="border px-4 py-2">35</td>
      <td className="border px-4 py-2">16</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">4</td>
      <td className="border px-4 py-2">Produkti i diseminacija</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">4</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">33</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">4.1</td>
      <td className="border px-4 py-2">Web sajt</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">4</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">33</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">4.2</td>
      <td className="border px-4 py-2">Dizajn baze podataka</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">7</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">30</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">4.3</td>
      <td className="border px-4 py-2">Prezentacija</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">12</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">25</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">5</td>
      <td className="border px-4 py-2">Upravljanje projektom</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">1</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">36</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">5.1</td>
      <td className="border px-4 py-2">Sveukupno upravljanje</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">1</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">36</td>
    </tr>
    <tr>
      <td className="border px-4 py-2">5.2</td>
      <td className="border px-4 py-2">Naučna koordinacija</td>
      <td className="border px-4 py-2">Ivana Tošić</td>
      <td className="border px-4 py-2">1</td>
      <td className="border px-4 py-2">36</td>
      <td className="border px-4 py-2">36</td>
    </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Form />
    <Footer />
        </>
  );
}
