import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import sanityClient from "../client.js";
import imageUrlBuilder from "@sanity/image-url";
import BlockContent from "@sanity/block-content-to-react";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

const builder = imageUrlBuilder(sanityClient);
function urlFor(source) {
  return builder.image(source);
}

export default function SingleVestEN() {
  const [singleVest, setsingleVest] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  const { slug } = useParams();

  useEffect(() => {
    sanityClient
      .fetch(
        `*[slug.current == "${slug}"]{
            title,
            _id,
            slug,
            autor,
            mainImage{
                asset->{
                    _id,
                    url
                }
            },
            body
        }`
      )
      .then((data) => setsingleVest(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singleVest) return <div>Loading...</div>;

  return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <main className="bg-white-100 min-h-screen py-12 px-4 md:p-12">
      <article className="container shadow-lg mx-auto max-w-screen-xl">
      <header className="relative">
          <div className="absolute h-full w-full flex items-center justify-center p-8">
            <div className="bg-white">
              <h1 className="text-2xl lg:text-2xl mb-4">
                {singleVest.title}
              </h1>
              <div className="flex justify-center text-gray-800">
              <p className="text-gray-800 px-2 py-2">
              Author: {singleVest.autor}
                      </p>
                <p className="flex items-center pl-2 text-2xl pb-4">
                  {singleVest.name}
                </p>
              </div>
            </div>
          </div>
        </header>
        <div className="px-4 py-28 md:px-16 lg:px-48 py-12 lg:py-24 max-w-full">
          <BlockContent
            blocks={singleVest.body}
            projectId="r99w5jgb"
            dataset="production"
          />
            <img
            src={singleVest.mainImage.asset.url}
            alt={singleVest.title}
            className="w-full"
          />
        </div>
      </article>
    </main>
    <FormEN />
    <FooterEN />
    </>

  );
}