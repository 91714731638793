import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function RadEN() {
  const [radData, setRad] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "radEN"]{
                title,
                slug,
                description,
                publishedAt,
                citiranost
            }`
      )
      .then((data) => setRad(data))
      .catch(console.error);
  }, []);

  return (
<>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container max-w-screen-xl mx-auto">
        <h1 className="text-5xl flex justify-center pb-4">
          Scientific papers
        </h1>
        <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-8">
          {radData &&
            radData.map((radEN, index) => (
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={radEN}>
                    <h3 className="text-blue-600 text-2xl hover:text-blue-700">
                            {radEN.title}
                    </h3>
                    <p className="text-gray-800 py-2">
                      {new Date(radEN.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{radEN.description}</p>
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                    <Link to={"/en/scientific-papers/" + radEN.slug.current} key={radEN.slug.current}>
                            Read more</Link></button>
                </article>
            ))}
        </div>
      </section>
    </main>
    <FormEN />
    <FooterEN />
    </>
  );
}