import React, {useEffect, useState} from "react";
import sanityClient from "../client";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";

export default function WorkPackageEN() {
    const [workPackageData, setworkPackageData] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "workPackage"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setworkPackageData(data))
        .catch(console.error);
    }, []);
    return (
        <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
        <main className="bg-white-100 min-h-screen p-12">
            <section className="container mx-auto">
                <h1 className="text-4xl flex pb-8">Work Package</h1>
                <section className="grid grid-cols-1 gap-4">
                    {workPackageData && workPackageData.map((workPackage, index) => (

                    
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={workPackage}>
                        <h3 className="text-blue-600 text-2xl hover:text-blue-700">
                                {workPackage.naslov}
                        </h3>
                        <p className="text-gray-700 py-4 dark:text-gray-400 mb-2">{workPackage.opis}</p>
                    </article>
                    ))}
                </section>
            </section>
        </main>
        </>
    )
}