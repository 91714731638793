import React, {useEffect, useState} from "react";
import sanityClient from "../client";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function EventEN() {
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");
    const [eventData, seteventData] = useState(null);

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "event"]{
            title,
            description,
            autor,
            mainImage{
                asset->{
                    _id,
                    url
                },
                alt
            },
        }`).then((data) => seteventData(data))
        .catch(console.error);
    }, []);
    return (
        <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
        <main className="bg-white-100 min-h-screen p-12">
            <section className="container max-w-screen-xl mx-auto">
                <h1 className="text-4xl flex pb-8">Upcoming events</h1>
                <h2 className="text-2xl flex pb-8">COMING EXTREMELY SOON</h2>
                {/* <section className="grid grid-cols-1 gap-8">
                    {eventData && eventData.map((event, index) => (
                    <article className="relative py-4 border-b border-gray-300 md:grid grid-cols-2 gap-2">
                        <img
                      src={event.mainImage.asset.url}
                      alt={event.title}
                      className="w-100 pb-4"
                    />
                    <span className="text-justify">
                        <h3 className="text-blue-600 text-2xl mb-1 hover:text-blue-700">
                                {event.title}
                        </h3>
                            <p className="mb-6 mt-1 text-s text-gray-700 leading-relaxed">{event.description}</p>
                            <p className="mb-6 mt-1 text-s text-gray-700 leading-relaxed">{event.autor}</p>
                            </span>
                    </article>
                    ))}
                </section> */}
            </section>
        </main>
        <FormEN />
    <FooterEN />
        </>
    )
}