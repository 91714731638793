import React from "react";
import imageLogoInstituta from '../logotip-instituta.png'
import imageLogoFakulteta from '../logotip-fakultet.png'
import imageLogoFondNauke from '../logotip-fond-za-nauku.png'
import imageLogoPrizma from '../logotip-prizma.png'
import sanityClient from "../client";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import gifBaner from '../animated-map.gif';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import SearchComponent from './SearchComponent';
import HeroBannerDesktop from './HeroBanerDesktop';
import { useMediaQuery } from "@uidotdev/usehooks";
import HeroBanerMobile from "./HeroBanerMobile";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function HomeEN() {
  const [vestData, setVest] = useState(null);
    const [reportData, setreportData] = useState(null);
    const [radData, setRad] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");
    useEffect(()=> {
        sanityClient.fetch(`*[_type == "reportEN"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setreportData(data))
        .catch(console.error);
    }, []);

    

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "vestEN"] | order(publishedAt desc){
                  title,
                  slug,
                  description,
                  publishedAt,
                  mainImage{
                      asset->{
                          _id,
                          url
                      },
                      alt
                  }
              }`
        )
        .then((data) => setVest(data))
        .catch(console.error);
    }, []);

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "radEN"] | order(publishedAt desc){
                  title,
                  slug,
                  description,
                  publishedAt,
                  abstract
              }`
        )
        .then((data) => setRad(data))
        .catch(console.error);
    }, []);
    return (
        <>
        <main>
        {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
        {/* <SearchComponent /> */}
        {allDevices && (
          <HeroBannerDesktop/>
          
        )}
      {isSmallDevice && (
          <HeroBanerMobile/>
          
        )}

            <section className="container max-w-screen-xl flex-col mx-auto py-20 border-b-2 border-gray-100">
                <h2 className="text-4xl text-blue-400 py-5 text-center">Extreme Weather Events in Serbia – Analysis, Modeling, and Impacts</h2>
                <p className="text-l px-4 text-justify md-px-2">"EXTREMES" focuses on researching increasingly frequent and intense weather and climate extremes, such as heatwaves, droughts, floods, and storms, which impact both people and nature, causing damages and losses in our country. Since 2000, these damages in our country have been estimated at over 7 billion euros. Throughout the project, our team will employ modern and innovative methodological approaches and the latest technologies to explain these events, their causes, analyze current consequences, and assess potential risks in the future.</p>
                <div className="flex justify-center gap-8 py-5">
                <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/goals-of-project">Project goals</a></button>
                <button className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"><a href="/en/team">Team members</a></button>
                </div>
            </section>

      <section className="container max-w-screen-xl mx-auto py-20 border-b-2 border-gray-100">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
      EXTREMES news</h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {vestData &&
            vestData.slice(0,3).map((vestEN, index) => (
              <>
              <article>
              <Link to={"en/news/" + vestEN.slug.current} key={vestEN.slug.current}>
                 <Card>
                 <Card.Img variant="top" src={vestEN.mainImage.asset.url}
                      alt={vestEN.mainImage.alt} />
                 <Card.Body>
                   <Card.Title className="text-blue-600 text-lg py-2">{vestEN.title}</Card.Title>
                   <Card.Text>
                   {vestEN.description}
                   </Card.Text>
                   <Link to={"en/news/" + vestEN.slug.current} key={vestEN.slug.current}>
                   <Button variant="primary" className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">Learn More</Button></Link>
                 </Card.Body>
               </Card>
               </Link>
               </article>
               </>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/news">Read All News
</a></button>
        </div>
        
        </div>

        
      </section>

      <section className="container max-w-screen-xl mx-auto py-20">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
      EXTREMES publications</h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {radData &&
            radData.slice(0,3).map((radEN, index) => (
                    <article className="relative pt-2 pb-4" key={radEN}>
                    <h3 className="text-blue-600 text-lg py-2 hover:text-blue-700">
                            {radEN.title}
                    </h3>
                    <p className="text-gray-800">
                      {new Date(radEN.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{radEN.description}</p>
                    <button className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">
                    <Link to={"/en/scientific-papers/" + radEN.slug.current} key={radEN.slug.current}>
                    View More</Link></button>
                </article>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/scientific-papers">Read more</a></button>
        </div>
        
        </div>

        
      </section>

            <section className="container max-w-screen-xl mx-auto py-20 border-t-2 border-gray-100">
                <h2 className="text-4xl text-blue-400 py-5 text-center">Partners</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-center items-center">
                <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-32 lg:w-48"/>
                </a>
                <a href="http://haos.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoInstituta} alt="Institut za meteorologiju" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/poziv/2022/06/program-prizma/" target="_blank" rel="noopener noreferrer"  className="mx-auto">
                <img src={imageLogoPrizma} alt="Prizma" className="w-32 lg:w-48"/>
                </a>
                </div>
            </section>

            


            
        </main>
        <FormEN />
    <FooterEN />
        </>
    )
}