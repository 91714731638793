import React, {useEffect, useState} from "react";
import sanityClient from "../client";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

export default function Report() {
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");
    const [reportData, setreportData] = useState(null);

    useEffect(()=> {
        sanityClient.fetch(`*[_type == "report"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setreportData(data))
        .catch(console.error);
    }, []);
    return (
        <>
        {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
        <main className="bg-white-100 min-h-screen p-12">
            <section className="container max-w-screen-xl mx-auto">
            <h1 className="text-4xl flex justify-center pb-4">
          EXTREMES tehnički izveštaji</h1>
                {/* <h2 className="text-2xl flex pt-8 justify-center">STIŽE EXTREMNO BRZO</h2> */}
                <section className="grid grid-cols-1 gap-4">
                    {reportData && reportData.map((report, index) => (

                    
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={report}>
                        <h3 className="text-blue-600 text-2xl hover:text-blue-700">
                                {report.naslov}
                        </h3>
                        <p className="text-gray-700 py-4 dark:text-gray-400 mb-2">{report.opis}</p>
                        <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                        <a
                            href={report.link}
                            alt={report.dugme}
                            target="_black"
                            rel="noopener noreferrer" download
                            >
                                {report.dugme}</a></button>
                    </article>
                    ))}
                </section>
            </section>
        </main>
        <Form />
    <Footer />
        </>
    )
}