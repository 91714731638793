import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logoExtremes from '../logo_extremes_veliki_bw.png';
import imageLogoFakulteta from '../logotip-fakultet.png';
import imageLogoFondNauke from '../logotip-fond-za-nauku.png';
import NavEN from "./NavEN";

import {
    ControlledMenu,
    MenuItem,
    useHover,
    useMenuState
  } from "@szhsin/react-menu";
  import "@szhsin/react-menu/dist/index.css";
  import "@szhsin/react-menu/dist/transitions/slide.css";
  
  const HoverMenu = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Extremes
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/o-projektu">O projektu</NavLink></MenuItem>
          <MenuItem><NavLink to="/ciljevi-projekta">Ciljevi projekta</NavLink></MenuItem>
          <MenuItem><NavLink to="/tim">Članovi tima</NavLink></MenuItem>
          <MenuItem><NavLink to="/work-packages">Work Packages</NavLink></MenuItem>
        </ControlledMenu>
      </>
    );
  };

  const HoverMenu11 = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Publikacije
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/naucni-radovi">Naučni radovi</NavLink></MenuItem>
          <MenuItem><NavLink to="/izvestaji">Tehnički izveštaji</NavLink></MenuItem>
        </ControlledMenu>

      </>
    );
  };
  
  const HoverMenu1 = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
              

        <button ref={ref} {...anchorProps}>
          Novosti
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/vesti">Vesti</NavLink></MenuItem>
          <MenuItem><NavLink to="/blog">Blog</NavLink></MenuItem>
          <MenuItem><NavLink to="/pozivi-za-dogadjaje">Pozivi za događaje</NavLink></MenuItem>
        </ControlledMenu>

      </>
    );
  };

  const HoverMenuWithTransition = () => {
    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: true });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Vesti
        </button>
  
        <ControlledMenu
          {...hoverProps}
          {...menuState}
          arrow
          anchorRef={ref}
          onClose={() => toggle(false)}
        >
          <MenuItem>Blog</MenuItem>
          <MenuItem>Pozivi za događaje</MenuItem>
          <MenuItem>Naučni radovi</MenuItem>
          <MenuItem>Tehnički izveštaji</MenuItem>
        </ControlledMenu>
      </>
    );
  };

export default function NavBarMobileEN() {
    
    return (
      <>
    <header className="bg-white-600 py-2 px-3">
        <div className="container mx-auto flex-col justify-between">
        {/* <div className="flex justify-center text-center py-2">
      <NavLink to="/" exact 
                activeClassName="text-white"
                className="inline-flex items-center px-3 mr-4 text-4xl font-bold cursive tracking-widest">
                  <img src={logoExtremes} alt="Extremes Project" className="w-20"/>
                </NavLink>
      <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-20"/>
              </a>
      <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-20"/>
            </a>
          </div> */}
            {/* <nav className="flex justify-center">
            <NavLink to="/" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                  <HoverMenu />
                </NavLink>
                <NavLink to="/publikacije" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                  <HoverMenu11 />
                </NavLink>
                <NavLink to="/vesti" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                <HoverMenu1 />
                </NavLink>
                <NavLink to="/katalog" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">Katalog</NavLink>
                <NavLink to="/kontakt" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">Kontakt</NavLink>

      </nav> */}
      <NavEN />
        </div>
    </header>
    </>
    )
}