import React from "react";
import imageLogoInstituta from '../logotip-instituta.png'
import imageLogoFakulteta from '../logotip-fakultet.png'
import imageLogoFondNauke from '../logotip-fond-za-nauku.png'
import imageLogoPrizma from '../logotip-prizma.png'
import sanityClient from "../client";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function PublikacijeEN() {
  const [vestData, setVest] = useState(null);
    const [reportData, setreportData] = useState(null);
    const [radData, setRad] = useState(null);
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const allDevices = useMediaQuery("only screen and (min-width : 769px)");
    useEffect(()=> {
        sanityClient.fetch(`*[_type == "reportEN"]{
            naslov,
            opis,
            link,
            dugme
        }`).then((data) => setreportData(data))
        .catch(console.error);
    }, []);

    

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "vestEN"]{
                  title,
                  slug,
                  description,
                  publishedAt,
                  mainImage{
                      asset->{
                          _id,
                          url
                      },
                      alt
                  }
              }`
        )
        .then((data) => setVest(data))
        .catch(console.error);
    }, []);

    useEffect(() => {
      sanityClient
        .fetch(
          `*[_type == "radEN"]{
                  title,
                  slug,
                  description,
                  publishedAt,
                  abstract
              }`
        )
        .then((data) => setRad(data))
        .catch(console.error);
    }, []);
    return (
        <>
        {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
        <main>
     {/* <section className="container max-w-screen-xl mx-auto py-20 border-b-2 border-gray-100">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
          Extremes reports
        </h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {reportData && reportData.slice(0,3).map((reportEN, index) => (

                    
<article className="relative pt-2 pb-4" key={reportEN}>
    <h3 className="text-blue-600 text-2xl hover:text-blue-700">
            {reportEN.naslov}
    </h3>
    <p className="text-gray-700 py-4 dark:text-gray-400 mb-2">{reportEN.opis}</p>
    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
    <a
        href={reportEN.link}
        alt={reportEN.dugme}
        target="_black"
        rel="noopener noreferrer"
        >
            {reportEN.dugme}</a></button>
</article>
))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/reports">See all tehnical reports</a></button>
        </div>
        
        </div>

        
      </section> */}

      <section className="container max-w-screen-xl mx-auto py-20">
      <h2 className="text-4xl text-blue-400 py-5 text-center">
          EXTREMES publications
        </h2>
        <div className="flex flex-col md:flex-row">
        <div className="flex-auto w-100 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-4 mx-2">
        {radData &&
            radData.slice(0,3).map((rad, index) => (
                    <article className="relative pt-2 pb-4" key={rad}>
                    <h3 className="text-blue-600 text-lg py-2 hover:text-blue-700">
                            {rad.title}
                    </h3>
                    <p className="text-gray-800">
                      {new Date(rad.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{rad.description}</p>
                    <button className="my-2 bg-blue-600 hover:bg-blue-700 p-2 text-white rounded">
                    <Link to={"/en/scientific-papers/" + rad.slug.current} key={rad.slug.current}>
                            Read more</Link></button>
                </article>
            ))}
            <button className="bg-blue-500 w-100 sm:h-20 md:h-20 lg:h-10 lg:w-300 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/scientific-papers">Read more</a></button>
        </div>
        
        </div>

        
      </section>

            <section className="container max-w-screen-xl mx-auto py-20 border-t-2 border-gray-100">
                <h2 className="text-4xl text-blue-400 py-5 text-center">Partners</h2>
                <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-4 gap-8 justify-center items-center">
                <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-32 lg:w-48"/>
                </a>
                <a href="http://haos.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoInstituta} alt="Institut za meteorologiju" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-32 lg:w-48"/>
                </a>
                <a href="https://fondzanauku.gov.rs/poziv/2022/06/program-prizma/" target="_blank" rel="noopener noreferrer"  className="mx-auto">
                <img src={imageLogoPrizma} alt="Prizma" className="w-32 lg:w-48"/>
                </a>
                </div>
            </section>

            


            
        </main>
        <FormEN />
    <FooterEN />
        </>
    )
}