import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

export default function Rad() {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  const [radData, setRad] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "rad"]{
                title,
                slug,
                description,
                publishedAt,
                citiranost
            }`
      )
      .then((data) => setRad(data))
      .catch(console.error);
  }, []);

  return (
    <>
    {allDevices && (
            <NavBar />
        )}
          {isSmallDevice && (
              <NavBarMobile/>
        )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container max-w-screen-xl mx-auto">
        <h1 className="text-4xl flex justify-center pb-4">
          EXTREMES Naučni radovi
        </h1>
        <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-8">
          {radData &&
            radData.map((rad, index) => (
                    <article className="relative pt-2 pb-4 border-b border-gray-200" key={rad}>
                    <h3 className="text-blue-600 text-2xl hover:text-blue-700">
                            {rad.title}
                    </h3>
                    <p className="text-gray-800 py-2">
                      {new Date(rad.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                    <p className="text-gray-700 py-2 dark:text-gray-400 mb-2">{rad.description}</p>
                    <button className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded">
                    <Link to={"/radovi/" + rad.slug.current} key={rad.slug.current}>
                            Pročitaj više</Link></button>
                </article>
            ))}
        </div>
      </section>
    </main>
    <Form />
    <Footer />
    </>
  );
}