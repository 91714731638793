import React from "react";
import image1 from "../extremes-horizontalno.png";
import NavBar from "./NavBar";
import NavBarMobile from "./NavBarMobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import Form from "./Form";
import Footer from "./Footer";

export default function CiljeviProjekta () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
      return ( 
    <>

        {allDevices && (
        <NavBar />
    )}
      {isSmallDevice && (
          <NavBarMobile/>
    )}
      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center items-center">
                <h1 className="text-5xl text-blue-400 font-semibold mb-4 text-gray-800 pb-2">
                    Ciljevi projekta
                </h1>
            </div>
        <div className="max-w-xl justify-center items-center">
            <img
              src={image1}
              alt="Project Image"
              className="object-contain max-w-full h-auto max-w-md"
            />
        </div>
    </div>
    </div>
   


    <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center">
                <h2 className="text-3xl font-semibold mb-2 text-gray-800">
                     Koji su ciljevi projekta EXTREMES?
                </h2>
            </div>
         <div className="max-w-xl">
             <p className="text-lg text-gray-700 text-justify mb-4">
            Projekat "EXTREMES" ima za cilj detaljnu analizu ekstremnih
              vremenskih i klimatskih događaja (toplotnih talasa, suša, obilnih
              padavina, štete od grada, guste magle, itd.) u prošlosti ali i
              njihove projekcije za budućnost. Jedan od fokusa će biti i
              uspostavljanje lakog, otvorenog i pouzdanog pristupa ovoj vrsti
              podataka i informacija kao podrške širokom spektru potencijalnih
              korisnika, a radi procene rizika i uticaja ovih ekstrema na
              različite aspekte života u našoj zemlji.
            </p>
        </div>
  </div>
</div>

      <hr className="my-8" />

      <div className="container mx-auto px-4 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="flex justify-center">
                <h2 className="text-3xl text-blue-400 font-semibold mb-2 text-gray-800">
              Ko ima korist od projekta EXTREMES?
            </h2>
            </div>
         <div className="max-w-xl">
             <p className="text-lg text-gray-700 text-justify mb-4">
              Klimatske promene i povećan broj ekstrema povezan sa njima
              predstavljaju jedan od globalnih problem koji okuplja inicijative
              i istraživanja iz celog sveta, a naša istraživačka grupa već
              godinama u nazad gradi čvrste veze sa međunarodnim istraživačkim
              grupama iz ove oblasti. Rezultati regionalnog klimatskog modela,
              koji je razvijen u okviru naše grupe, postali su deo i Digitalnog
              atlasa Međuvladinog panela za klimatske promene Ujedinjenih
              nacija (
              <a
                href="https://interactive-atlas.ipcc.ch"
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://interactive-atlas.ipcc.ch
              </a>
              ), koji predstavlja globalno najrelevantniji izvor informacija o
              klimatskim promenama. Prateći ovu dobru tradiciju, sigurni smo i
              da će rezultati projekat EXTREMES imati odjeka na međunarodnom
              nivou.
            </p>
            <p className="text-lg text-gray-700 text-justify mb-4">
              S obzirom na sveobuhvatni uticaj koji imaju vremenski i klimatski
              događaji na ljude, okolinu i infrastrukturu rezultati koji će
              proisteći iz projekta "EXTREMES" biće korisni za građanstvo u
              različitim oblastima i delatnostima. Pored klasifikacije ekstremnih
              događaja, i podrške njihovom boljem razumevanju, koja će služiti
              kompletnijem sagledavanju njihovog uticaja u prošlosti u našoj
              zemlji, naš tim će obezbediti i uvid u buduće trendove ektremnih
              događaja koji će se moći koristiti u planiranju strateški značajnih
              delatnosti kao što su poljoprivreda, energetika, zaštita životne
              sredine, vidni resursi, infrastruktura, osiguranje, turizam itd.
            </p>
          </div>
        </div>
      </div>
      <Form />
    <Footer />
    </>
  );
}