import React from "react";
import image1 from "../o-projektu-grupna-fotografija.png";
import image2 from "../extremes-horizontalno-blue.png";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

import imagePoster1 from "../e-poster1.png";
import imagePoster2 from "../e-poster2.png";
import imagePoster3 from "../e-poster3.png";

export default function OprojektuEN () {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");
  return (
    <>
   {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
<div className="py-8">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
            <img
              src={imagePoster2}
              alt="Project Image 1"
              className="object-contain max-w-full h-auto"
            />
            <img
              src={imagePoster1}
              alt="Project Image 2"
              className="object-contain max-w-full h-auto"
            />
            <img
              src={imagePoster3}
              alt="Project Image 3"
              className="object-contain max-w-full h-auto"
            />
          </div>
        </div>
      </div>

      <div className="container mx-auto px-4 py-8 poster-color">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div className="flex justify-center items-center">
            <img
              src={image1}
              alt="Project Image"
              className="object-contain max-w-full h-auto max-w-md"
            />
          </div>
          <div className="max-w-xl"> 
            <h1 className="text-5xl font-semibold mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">About the Project</h1>
            <p className="text-lg text-gray-700 text-justify mb-4">
            "EXTREMES" focuses on researching increasingly frequent and intense weather and climate extremes, such as heatwaves, droughts, floods, and storms, which impact people and nature and cause damages and losses in our country. Since 2000, these damages in our country have been estimated at over 7 billion euros. During the project, our team will use modern and innovative methodological approaches and the latest technologies to explain these events, their causes, analyze current consequences, and possible risks in the future.
            </p>
            <p className="text-lg text-gray-700 text-justify mb-4  ">
            The main issues the project will address include identifying relevant extreme events, analyzing synoptic situations associated with these events, improving models for predicting rainfall, hail, strong winds, and lightning, forecasting extreme fog events, as well as determining the impact of climate change, especially future changes, on extreme events in Serbia.
            </p>
            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"><a href="/en/team">Team members</a></button>
          </div>
        </div>
      </div>




      <div className="container mx-auto px-4 py-8">
      <div className="flex grid grid-cols-1 md:grid-cols-2 gap-8">
    <div className="max-w-xl mx-auto">
    <h2 className="text-4xl font-semibold mb-4 text-gray-800 border-b-2 border-gray-300 pb-2">Methodology and Results</h2>
    <p className="text-lg text-gray-700 text-justify mb-4">
    Given the wide range of temporal and spatial scales of extreme events, as well as the need to process large amounts of data, methodological approaches will be very complex and diverse. One approach involves identifying and analyzing significant extreme events using classification systems of synoptic situations developed for Serbia, and through the analysis of observational data, as well as results of numerical climate simulations. Additionally, detailed analysis of convective clouds and microphysical processes will be supported by using a numerical model to simulate these processes. The project will also analyze and forecast extreme fog events, which includes the use of a regional weather prediction model. Changes in extremes through distributions of key variables in future climate will be analyzed, allowing for better understanding of potential changes in intensity and frequency of extreme weather and climate events. Machine learning and artificial intelligence methods will be utilized in this type of analysis, among others. Finally, the development of a unique and comprehensive database with information on extreme events in Serbia is a key project goal, which will support various sectoral impact studies and risk assessments. </p>
    </div>
    <div className="justify-center items-center max-w-xl ">

      <img
        src={image2}
        alt="Project Image"
        className="object-contain max-w-full h-auto max-w-md"
      />
          <p className="text-lg text-gray-700 text-justify">
          The project team will work to ensure that the central result of the project is a unique database created in a format that is easily understandable and accessible for wide use, starting from the scientific community, national institutions, and local communities to individual users. Since the interpretation of our results is also significant for the general public, dissemination of project results through summer schools, seminars, scientific-popular lectures, as well as through media and social networks, will also be a very important component of the project.
      </p>
    </div>
  </div>
</div>
<FormEN />
    <FooterEN />
    </>
  );
}