import React from "react";
import slikaForm from "../kontaktirajte-extremes.png";

export default function Form() {
  return (
    <>
    {/* <div className="flex justify-center">
      <div className="max-w-md w-full px-4 py-8 mt-8 bg-white rounded-lg shadow-md text-center">
        <h2 className="text-2xl font-bold mb-4">
          Da li imate pitanja za EXTREMES stručnjake?
        </h2>
        <p className="text-gray-600 mb-6">
          Postoji nešto što želite da nas pitate? Popunite formu ispod, a mi ćemo se potruditi da odgovorimo EXTREMNO brzo.
        </p>

    <form name="contact" method="post">
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label htmlFor="name">Vaše ime</label> <br />
        <input type="text" id="name" name="name" required />
      </p>
      <p>
        <label htmlFor="email">Vaš email</label> <br />
        <input type="email" id="email" name="email" required />
      </p>
      <p>
        <label htmlFor="message">Vaše pitanje</label> <br />
        <textarea id="message" name="message" required></textarea>
      </p>
      <p>
        <input type="submit" value="Pošalji" />
      </p>
    </form>
    </div>
    </div> */}

    <div className="flex flex-col lg:flex-row max-w-screen-xl mx-auto border-t-2 border-gray-100">
    <div className="w-full lg:w-1/2 p-4">
          <img src={slikaForm} alt="Extremes project"/>

          
        </div>
        <div className="w-full lg:w-1/2 p-4 text-center">
        <h2 className="text-2xl font-bold mb-4">
          Da li imate pitanja za EXTREMES stručnjake?
        </h2>
        <p className="text-gray-600 mb-6">
          Postoji nešto što želite da nas pitate? Popunite formu ispod, a mi ćemo se potruditi da odgovorimo EXTREMNO brzo.
        </p>
    <form name="contact" method="post">
      <input type="hidden" name="form-name" value="contact" />
      <p>
        <label htmlFor="name" className="block text-gray-700 font-bold mb-2">Vaše ime</label> <br />
        <input className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
 type="text" id="name" name="name" required />
      </p>
      <p>
        <label htmlFor="email" className="block text-gray-700 font-bold mb-2">Vaš email</label> <br />
        <input type="email" id="email" name="email" required className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
 />
      </p>
      <p>
        <label htmlFor="message" className="block text-gray-700 font-bold mb-2">Vaše pitanje</label> <br />
        <textarea id="message" name="message" required className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-400"
></textarea>
      </p>
      <p>
        <input type="submit" value="Pošalji"/>
      </p>
    </form>
        </div>
      </div>
    </>
  );
}