import React, { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import logoExtremes from '../logo_extremes_veliki_bw.png';
import imageLogoFakulteta from '../logotip-fakultet.png';
import imageLogoFondNauke from '../logotip-fond-za-nauku.png';

import {
    ControlledMenu,
    MenuItem,
    useHover,
    useMenuState
  } from "@szhsin/react-menu";
  import "@szhsin/react-menu/dist/index.css";
  import "@szhsin/react-menu/dist/transitions/slide.css";
  
  const HoverMenu = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Extremes
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/en/about-project">About the project</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/goals-of-project">Project goals</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/team">Team members</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/work-packages">Work Packages</NavLink></MenuItem>
        </ControlledMenu>

      </>
    );
  };

  const HoverMenu11 = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Publications
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/en/scientific-papers">Scientific papers</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/reports">Technical reports</NavLink></MenuItem>
        </ControlledMenu>

      </>
    );
  };
  
  const HoverMenu1 = () => {
    const ref = useRef(null);
    const [isOpen, setOpen] = useState(false);
    const { anchorProps, hoverProps } = useHover(isOpen, setOpen);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          News
        </button>
  
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          <MenuItem><NavLink to="/en/news">News</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/blog">Blog</NavLink></MenuItem>
          <MenuItem><NavLink to="/en/upcoming-events">Upcoming events</NavLink></MenuItem>
        </ControlledMenu>

      </>
    );
  };

  const HoverMenuWithTransition = () => {
    const ref = useRef(null);
    const [menuState, toggle] = useMenuState({ transition: true });
    const { anchorProps, hoverProps } = useHover(menuState.state, toggle);
  
    return (
      <>
        <button ref={ref} {...anchorProps}>
          Vesti
        </button>
  
        <ControlledMenu
          {...hoverProps}
          {...menuState}
          arrow
          anchorRef={ref}
          onClose={() => toggle(false)}
        >
          <MenuItem><a href="/blog" target="_blank" rel="noopener noreferrer" className="mx-auto">Blog</a></MenuItem>
          <MenuItem>Pozivi za događaje</MenuItem>
          <MenuItem>Naučni radovi</MenuItem>
          <MenuItem>Tehnički izveštaji</MenuItem>
        </ControlledMenu>
      </>
    );
  };

export default function NavBarEN() {
    
    return (
      <>
    <header className="bg-white-600 py-2 px-3">
        <div className="container mx-auto flex justify-between">
            <nav className="flex">
            <NavLink to="/en" exact 
                activeClassName="text-white"
                className="inline-flex items-center px-3 mr-4 text-4xl font-bold cursive tracking-widest">
                  <img src={logoExtremes} alt="Extremes Project" className="w-20"/>
                </NavLink>
            <NavLink to="/en/about-project" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                  <HoverMenu />
                </NavLink>
                <NavLink to="/en/publications" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                  <HoverMenu11 />
                </NavLink>
                <NavLink to="/en/news" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">
                <HoverMenu1 />
                </NavLink>
                <NavLink to="/en/catalog" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">Catalog</NavLink>
                <NavLink to="/en/contact" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">Contact</NavLink>
                <NavLink to="/" 
                activeClassName="text-black-100 bg-blue-100"
                className="inline-flex items-center rounded text-black-100 hover:text-blue-600 px-3">RS</NavLink>

      {/* <HoverMenuWithTransition /> */}
      </nav>
      <div className="inline-flex">

      <div className="inline-flex justify-end py-6">
                <SocialIcon url="https://www.instagram.com/extremesproject/" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{height:35 , width: 35}} />
                <SocialIcon url="https://twitter.com/EXTREMESproject" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{height:35 , width: 35}} />
                <SocialIcon url="https://www.linkedin.com/company/extremes-project/" className="mr-4" target="_blank" bgColor="#000" fgColor="#fff" style={{height:35 , width: 35}} />

            </div>

      <div className="inline-flex justify-end">
      <a href="https://fondzanauku.gov.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFondNauke} alt="Fond za nauku" className="w-20"/>
              </a>
      <a href="http://www.ff.bg.ac.rs/" target="_blank" rel="noopener noreferrer" className="mx-auto">
                <img src={imageLogoFakulteta} alt="Fizički fakultet" className="w-20"/>
            </a>
          </div>

            
            </div>

        </div>
    </header>
    </>
    )
}