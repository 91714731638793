import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import sanityClient from "../client.js";
import { useMediaQuery } from "@uidotdev/usehooks";
import NavBarEN from "./NavBarEN";
import NavBarMobileEN from "./NavBarMobileEN";
import FooterEN from "./FooterEN";
import FormEN from "./FormEN";

export default function Vest() {
  const [vestData, setVest] = useState(null);
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const allDevices = useMediaQuery("only screen and (min-width : 769px)");

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "vestEN"] | order(publishedAt desc) {
                title,
                slug,
                description,
                publishedAt,
                autor,
                mainImage{
                    asset->{
                        _id,
                        url
                    },
                    alt
                }
            }`
      )
      .then((data) => setVest(data))
      .catch(console.error);
  }, []);

  return (
    <>
    {allDevices && (
        <NavBarEN />
    )}
      {isSmallDevice && (
          <NavBarMobileEN/>
    )}
    <main className="bg-white-100 min-h-screen p-12">
      <section className="container max-w-screen-md mx-auto">
        <h1 className="text-5xl flex justify-center pb-4">
          EXTREMES News
        </h1>
        <div className="grid md:grid-cols-1 lg:grid-cols-1 gap-8">
          {vestData &&
            vestData.map((vestEN, index) => (
              <article>
                <Link to={"/en/news/" + vestEN.slug.current} key={vestEN.slug.current}>
                  <span
                    className="block relative rounded shadow leading-snug bg-white border-2"
                    key={index}
                  >
                    <div className="grid md:grid-cols-2 lg:grid-cols-2">
                    <img
                      src={vestEN.mainImage.asset.url}
                      alt={vestEN.mainImage.alt}
                      className="w-full h-full rounded-r"
                    />

                <span className="block relative h-full flex flex-col pt-4 px-4 pb-4">
                      <p className="text-gray-800 px-2 py-4">
                      {new Date(vestEN.publishedAt).toLocaleDateString('en-GB', {
month: '2-digit',day: '2-digit',year: 'numeric'})}
                      </p>
                      <h3 className="text-blue-600 text-lg font-blog px-2 py-2">
                        {vestEN.title}
                      </h3>
                      <p className="text-gray-800 px-2 py-2">
                      {vestEN.description}
                      </p>
                      <p className="text-gray-800 px-2 py-2">
                      Author: {vestEN.autor}
                      </p>
                    </span>
                    </div>
                  </span>
                </Link>
              </article>
            ))}
        </div>
      </section>
    </main>
    <FormEN />
    <FooterEN />
    </>
  );
}